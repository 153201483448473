import React, { useContext, useEffect, useState } from "react";
import {
    CardWrapper,
    CardImageWrapper,
    CardStatWrapper,
    CardStatLabel,
    CardModelText,
    ExpandedCardWrapper,
    CardStatGrid,
    CardStatValue,
    CardVinWrapper,
    CardModelWrapper,
    CardVinText
} from "./KadcarCardStyles";
import Tilt from "react-parallax-tilt";
import { AiOutlineClose } from "react-icons/ai";
import unknown from "../../../assets/images/logo-nobackground.svg"
// import CAR from "../../../assets/images/kc.jpg"
import { KadcarGarageContext } from "../KadcarGarageContextProvider";
import { PactContext } from "../../../pact/PactContextProvider";
import { WalletModal } from "../../../walletInteractions/WalletModal";
import { formatAssetIpfsUrl, tryLoadLocal } from "../../../utils/utils";
import { IPFS_URL_PREFIX, IPFS_URL_SUFFIX, USER_WEBP_URLS } from "../../../utils/Constants";
import { KadcarGameContext } from "../KadcarGameContextProvider";

// import logoPixelated from "../../../assets/images/kadcarsHomeCropped.png"

const KadcarCardExpanded = ({ kadcarNft, children }) => {
    const { setSelectedKadcar } = useContext(KadcarGarageContext);

    function handleCloseButtonClicked() {
        setSelectedKadcar(null);
    }

    return (
        <ExpandedCardWrapper>
            <div style={{ position: 'absolute', paddingLeft: '10px', paddingTop: '10px', cursor: 'pointer' }}>
                <AiOutlineClose onClick={handleCloseButtonClicked} />
            </div>
            {children}
        </ExpandedCardWrapper>
    )
}

const KadcarCardStat = ({ children, label, value }) => {
    return (
        <CardStatGrid>
            <CardStatLabel>
                {label}:
            </CardStatLabel>
            <CardStatValue>
                {children}
            </CardStatValue>
        </CardStatGrid>
    )
}

const KadcarCard = ({ kadcarNft, blank, index }) => {
    const { account, isXwallet } = useContext(PactContext);
    const { setSelectedKadcar } = useContext(KadcarGarageContext);
    const { loadingNfts } = useContext(KadcarGameContext);
    const [showWalletNameModal, setShowWalletNameModal] = useState(false);


    function handleCardClicked() {
        if (!blank && account !== null) {
            setSelectedKadcar(kadcarNft);
        }
    }

    function getWebpUrlFromLocalStorage(index) {
        let urls = tryLoadLocal(USER_WEBP_URLS);
        return urls[index];
    }

    return (
        <>
            <Tilt>
                {
                    blank ?
                        <CardWrapper onClick={handleCardClicked}>
                            <CardModelWrapper>
                                <CardModelText>
                                    ?
                                </CardModelText>
                            </CardModelWrapper>
                            <CardImageWrapper image={unknown} />
                            <CardVinWrapper>
                                <CardVinText>
                                    VIN# ?
                                </CardVinText>
                            </CardVinWrapper>
                            <CardStatWrapper>
                                <KadcarCardStat label={"Top Speed"} value={"? kmph"} />
                                <KadcarCardStat label={"Acceleration"} value={"? s"} />
                                <KadcarCardStat label={"Horse Pwr"} value={"? HP"} />
                                <KadcarCardStat label={"Torque"} value={"? Nm"} />
                                <KadcarCardStat label={"Drive Train"} value={"? WD"} />
                                <KadcarCardStat label={"Weight"} value={"? kg"} />
                            </CardStatWrapper>
                        </CardWrapper> :
                        <>
                            {
                                loadingNfts ?
                                    <CardWrapper onClick={handleCardClicked}>
                                        <CardModelWrapper>
                                        </CardModelWrapper>
                                        {/* <CardImageWrapper image={unknown} /> */}
                                        {/* <CardImageWrapper image={kadcarNft?.uri} /> */}
                                        <CardVinWrapper>
                                            <CardVinText>
                                                Loading your Kadcars
                                            </CardVinText>
                                        </CardVinWrapper>
                                        <CardStatWrapper>
                                            <KadcarCardStat label={"Top Speed"} value={"? kmph"} />
                                            <KadcarCardStat label={"Acceleration"} value={"? s"} />
                                            <KadcarCardStat label={"Horse Pwr"} value={"? HP"} />
                                            <KadcarCardStat label={"Torque"} value={"? Nm"} />
                                            <KadcarCardStat label={"Drive Train"} value={"? WD"} />
                                            <KadcarCardStat label={"Weight"} value={"? kg"} />
                                        </CardStatWrapper>
                                    </CardWrapper> :
                                    <CardWrapper onClick={handleCardClicked} style={{ cursor: 'pointer' }}>
                                        <CardModelWrapper>
                                            <CardModelText>
                                                Kadcar {kadcarNft.data[1]['datum']['vehicle-information']['model']} / VIN# {kadcarNft.data[1]['datum']['vehicle-information']['vin']}
                                            </CardModelText>
                                        </CardModelWrapper>
                                        {/* <CardImageWrapper image={ () => getWebpUrlFromLocalStorage(index) }/> */}
                                        {/* <CardImageWrapper image={IPFS_URL_PREFIX + "/ipfs/" + kadcarNft.uri['data'].split('//')[1]} /> */}
                                        <CardImageWrapper image={"https://" + kadcarNft.uri['data'].split('//')[1] + IPFS_URL_SUFFIX} />
                                        {/* <CardImageWrapper image={unknown} /> */}
                                        <CardVinWrapper>
                                            <CardVinText>
                                                {/* VIN# {kadcarNft['nft-id']} */}
                                                {/* VIN# { kadcarNft.data[1]['datum']['vehicle-information']['vin'] } */}
                                                {kadcarNft.data[0]['datum']['components'][0]["stats"][1]["val"]["id"]}&nbsp;
                                            </CardVinText>
                                        </CardVinWrapper>
                                        <CardStatWrapper>
                                            <KadcarCardStat label={"Top Speed"} value={"? kmph"}>
                                                {kadcarNft.data[0]['datum']['components'][4]["stats"][0]["val"]["value"].toFixed(2)}&nbsp;kmph
                                            </KadcarCardStat>
                                            <KadcarCardStat label={"Acceleration"} value={"? s"}>
                                                {kadcarNft.data[0]['datum']['components'][4]["stats"][1]["val"]["value"]}&nbsp;s
                                            </KadcarCardStat>
                                            <KadcarCardStat label={"Horse Pwr"} value={"? HP"}>
                                                {
                                                    kadcarNft.data[0]['datum']['components'][2]["stats"][0]["val"][0]["horse-power"]["value"] +
                                                    kadcarNft.data[0]['datum']['components'][2]["stats"][0]["val"][1]["horse-power"]["value"] +
                                                    kadcarNft.data[0]['datum']['components'][2]["stats"][0]["val"][2]["horse-power"]["value"]
                                                }
                                                &nbsp;HP
                                            </KadcarCardStat>
                                            <KadcarCardStat label={"Torque"} value={"? Nm"}>
                                                {
                                                    kadcarNft.data[0]['datum']['components'][2]["stats"][0]["val"][1]["torque"]["value"] +
                                                    kadcarNft.data[0]['datum']['components'][2]["stats"][0]["val"][2]["torque"]["value"]
                                                }&nbsp;nm
                                            </KadcarCardStat>
                                            <KadcarCardStat label={"Drive Train"} value={"? WD"}>
                                                {kadcarNft.data[0]['datum']['components'][3]["stats"][0]["val"][0]["drive-train-type"]}&nbsp;
                                            </KadcarCardStat>
                                            <KadcarCardStat label={"Weight"} value={"? kg"}>
                                                {kadcarNft.data[0]['datum']['components'][4]["stats"][2]["val"]["value"]}&nbsp;kg
                                            </KadcarCardStat>
                                        </CardStatWrapper>
                                    </CardWrapper>
                            }
                        </>
                }
            </Tilt>
            <WalletModal show={showWalletNameModal} setShow={setShowWalletNameModal} isXwallet={isXwallet} />
        </>

    );
}

export {
    KadcarCard,
    KadcarCardExpanded
}