import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import PagerContainer from "./PagerContainer";

const Hero = () => {
    return (
        <Box pt={["224px", null, "11rem", "12rem"]} pb={["15.87rem"]} id="">
            <PagerContainer>
                <Box>
                    <Text
                        className="textClip"
                        fontSize={["40px", null, "80px"]}
                        maxW="31.75rem"
                        lineHeight={"normal"}
                    >
                        Racing Redefined
                    </Text>
                    <Text
                        mt={["8px", "16px"]}
                        fontSize={["16px", null, "26px"]}
                        maxW="38.6rem"
                        color="#F3F2F2"
                    >
                        Revolutionizing the NFT space. Web 3 gaming project with
                        upgradable NFTs.
                    </Text>
                    <Flex mt={["24px", "32px", "56px"]}>
                        <Button
                            bg=" linear-gradient(263deg, #E48044 0%, #DF6620 98.71%)"
                            h="auto"
                            // h="4.25rem"
                            py="12px"
                            px="40px"
                            fontSize={"16px"}
                            color="white"
                            lineHeight="36px"
                            rounded="8px"
                            _hover={{}}
                            _active={{ opacity: 0.7 }}
                            className="smZindex"
                            as="a"
                            href="#mint"
                        >
                            Play beta
                        </Button>
                    </Flex>
                </Box>
            </PagerContainer>
        </Box>
    );
};

export default Hero;
