import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import PagerContainer from "./PagerContainer";
import H_2 from "../assets/iso/h_2.png";

const R2r = () => {
    return (
        <Box
            background={`linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${H_2}), lightgray 0px 0px / 100% 108.889% no-repeat`}
            // bgSize={"cover"}
            // bgPos={"center"}
            bgRepeat={"no-repeat"}
            bgSize={"cover"}
            id="r2r"
        >
            <PagerContainer>
                <Flex align="center" justify="center" minH="100vh">
                    <Box>
                        <Text
                            className="textClip"
                            fontSize={["40px", "50px", "64px"]}
                            maxW="748px"
                            mx="auto"
                            lineHeight={"normal"}
                            textAlign="center"
                        >
                            READY-2-RENDER (R2R)
                        </Text>

                        <Text
                            mt="1.44rem"
                            fontSize={["16px", null, "24px"]}
                            maxW="917px"
                            mx="auto"
                            textAlign="center"
                            color="#F3F2F2"
                        >
                            R2R is an in house build oracle that performs both
                            the upgrade on the NFT metadata on the blockchain as
                            well visually in the game.
                        </Text>
                    </Box>
                </Flex>
            </PagerContainer>
        </Box>
    );
};

export default R2r;
