import { Box, Text, Flex, Button, Stack, Link } from "@chakra-ui/react";
import React from "react";
import PagerContainer from "./PagerContainer";
import H_3 from "../assets/iso/h_3.png";

const HowToPlay = () => {
    return (
        <Box
            background={`linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${H_3}), lightgray -146px -483.112px / 199.479% 188.401% no-repeat`}
            bgPos={"center"}
            bgSize={"cover"}
            bgRepeat="no-repeat"
            pt={["134px", null, "220px"]}
            pb={["203px", null, "428px"]}
            id="mint"
        >
            <PagerContainer>
                <Flex align="center">
                    <Box>
                        <Text
                            className="textClip"
                            fontSize={["36px", null, "80px"]}
                            textAlign={["center", null, "left"]}
                            lineHeight={"normal"}
                        >
                            How to play
                        </Text>
                        <Text
                            mt={["16px", null, "56px"]}
                            fontSize={["16px", null, "24px"]}
                            maxW={["22.3rem", "44rem", "849px"]}
                            color="#F3F2F2"
                            textAlign={["center", null, "left"]}
                        >
                            Every KadCars NFT owner can test out our open beta
                            game by owning a NFT and dowloading the game
                        </Text>

                        <Stack
                            mt={["24px", null, "80px"]}
                            spacing={["0.75rem", null, "2rem"]}
                            alignItems={["center", null, "unset"]}
                        >
                            <Link
                                border="2px"
                                borderColor={"#E48044"}
                                px={["28px", null, "24px"]}
                                py={["16px", null, "20px"]}
                                w="fit-content"
                                fontSize={["16px", null, "40px"]}
                                rounded="8px"
                                // h="3.7rem"
                                className="textClip"
                                cursor="pointer"
                                target="_blank"
                                href="https://drive.google.com/file/d/1RIU8m3GUa22aDRjYmWY4GCtL0dA2IoUo/view?usp=sharing"
                                _hover={{}}
                                _focus={{}}
                            >
                                READ INSTRUCTIONS
                            </Link>{" "}
                            {/* <Text
                                border="2px"
                                borderColor={"#E48044"}
                                px="1.5rem"
                                py={["1.25rem", null, "0.25rem"]}
                                w="fit-content"
                                fontSize={["1rem", null, "1.5rem", "2.5rem"]}
                                rounded="0.5rem"
                                // h="3.7rem"
                                className="textClip"
                                cursor="pointer"
                                as="button"
                                _hover={{}}
                                _focus={{}}
                            >
                                DOWNLOAD
                            </Text> */}
                            <Link
                                px="1.5rem"
                                py={["16px", null, "20px"]}
                                w="fit-content"
                                fontSize={["16px", null, "17px", "40px"]}
                                rounded="0.5rem"
                                bg="linear-gradient(263deg, #E48044 0%, #DF6620 98.71%)"
                                color="white"
                                cursor="pointer"
                                _hover={{}}
                                _focus={{}}
                                target="_blank"
                                href="https://isoko.xyz/"
                            >
                                Mint
                            </Link>
                        </Stack>

                        {/* <Flex mt={["1.5rem", "2rem", "3.5rem"]}>
                        <Button
                            bg=" linear-gradient(263deg, #E48044 0%, #DF6620 98.71%)"
                            h="4.25rem"
                            py="1rem"
                            px="2rem"
                            fontSize={"1rem"}
                            color="white"
                            lineHeight="normal"
                            rounded="0.5rem"
                            _hover={{}}
                            _active={{ opacity: 0.7 }}
                            className="smZindex"
                        >
                            LEARN MORE
                        </Button>
                    </Flex> */}
                    </Box>
                </Flex>
            </PagerContainer>
        </Box>
    );
};

export default HowToPlay;
