import { useContext, useState, useEffect } from "react";
import { getKadenaConnectStatus, getSelectedAccount, requestAccount } from "../kadenaInteraction/KadenaApi";
import { PactContext } from "../pact/PactContextProvider";

function useCheckKadenaAccountConnection(hasExtension) {
    const [isConnected, setIsConnected] = useState(null);

    async function checkKadenaConnection() {
        const response = await getKadenaConnectStatus();
        response.status === 'success' ? setIsConnected(true) : setIsConnected(false);
    }

    useEffect(() => {
        hasExtension && checkKadenaConnection();
    }, [hasExtension]);
    
    return isConnected;
}

function useDetectAccountSwap() {
    const { account, netId, setConnectedAccount } = useContext(PactContext);
    window.kadena.on('res_accountChange', async (e) => {
        console.log(e)
        const res = await getKadenaConnectStatus(netId);
        console.log(res)
    })
}

export {
    useCheckKadenaAccountConnection,
    useDetectAccountSwap
}