//Network IDs
const MAINNET_NETWORK_ID = "mainnet01";
const TESTNET_NETWORK_ID = "testnet04";
const NETWORK_ID = MAINNET_NETWORK_ID;

//Default values
const DEFAULT_GAS_PRICE = 0.00000001;
const DEFAULT_GAS_LIMIT = 150000;
const DEFAULT_TTL = 600;
const DEFAULT_CHAIN_ID = "13";
const POLL_INTERVAL_S = 5;

//Labels
const USER_KADCAR_GALLERY_LABEL = "USER";
const ALL_KADCAR_GALLERY_LABEL = "ALL";

//Local account key
const LOCAL_ACCOUNT_BALANCE_KEY = "LOCAL_ACCOUNT_BALANCE_KEY";
const LOCAL_ACCOUNT_KEY = "LOCAL_ACCOUNT_KEY";
const LOCAL_CHAIN_ID = "LOCAL_CHAIN_ID";

//X wallet indicator
const IS_X_WALLET_KEY = "IS_X_WALLET_KEY";
const X_WALLET_INSTALLED = "X_WALLET_INSTALLED";

//IPFS
// const IPFS_URL_PREFIX = "https://ipfs.io"
const IPFS_URL_SUFFIX = ".ipfs.nftstorage.link"
const IPFS_URL_PREFIX = "https://kadcarsgateway.myfilebase.com"
const USER_WEBP_URLS = "USER_WEBP_URLS";
const USER_GLB_URLS = "USER_GLB_URLS";
const ALL_WEBP_URLS = "ALL_WEBP_URLS";

//NFT Models
const KADCAR_NFT_OPTIONS = [
    // { value: 'K1', label: 'K1' },
    { value: 'K2', label: 'K:2' }
]

const STICKER_LOCATIONS = [
    { value: 'Door', label: 'Door'},
    { value: 'Hood', label: 'Hood'},
    { value: 'Wrap', label: 'Bottom Wrap'}
]

//Request response constants
const DEFAULT_REQUEST_HEADERS = { "Content-Type": "application/json" };
const POST_METHOD = "POST";
const GET_METHOD = "GET";

//Guards
// const ACCOUNT_GUARD = "";

//Contract constants
const KADCAR_NFT_COLLECTION = "k2";
const KADCAR_TEST_COLLECTION = "k:2";
// const KADCAR_TEST_COLLECTION = "k2:final";

//Module names on Kadena
const UNIVERSAL_LEDGER = "free.universal-ledger";
const KADCARS_FACTORY = "free.kadcar-factory";
// const KADCARS_FACTORY = "free.kadcars-factory";
const KADCARS_NFT_POLICY = "free.kadcars-nft-policy";
// const KADCARS_NFT_POLICY = "free.kadcars-ledger-policy";

//Social URLs
const DISCORD_URL = "https://discord.gg/PPX9CupYZT";
const MEDIUM_URL = "https://medium.com/@kadcarsnft";
const TWITTER_URL = "https://twitter.com/kadcarsnft";
// const KADCARS_URL = "https://kadcarsnft.app.runonflux.io/";
const MUSTANG_KNIGHTS_URL = "https://www.instagram.com/mustangknights/";
const FLUX_LABS_URL = "https://runonflux.io/fluxlabs.html?utm_campaign=%20Weekly%20Kadena%20Ecosystem%20Update&utm_medium=email&utm_source=Revue%20newsletter";

//Admin
const ADMIN_ADDRESS = "k:ccf45d4b9e7a05b1f8ae03e362fac9502610d239191a3215774c5251a662c1eb";
const NEW_ADMIN_ADDRESS = "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5";

//Screen names
const SCREEN_NAMES = {
    MY_KADCARS  :  "MY_KADCARS",
    ALL_KADCARS :  "ALL_KADCARS",
    MINT_KADCAR :  "MINT_KADCAR",
    KADCAR_PREVIEW : "KADCAR_PREVIEW"
}

//REGEX
const REGEX_FOR_NFT_ID = ".*([0-9]+:[0-9]+).*";

//Time
const S_TO_MS_MULTIPLIER = 1000;
const DEFAULT_POLLING_TIMER = 10000;

//KDA API Calls
const KDA_CONNECT = 'kda_connect';
const KDA_GET_CHAIN = 'kda_getChain';
const KDA_DISCONNECT = 'kda_disconnect';
const KDA_GET_NETWORK = 'kda_getNetwork';
const KDA_CHECK_STATUS = 'kda_checkStatus';
const KDA_REQUEST_SIGN = 'kda_requestSign';
const KDA_QUICK_SIGN = 'kda_requestQuickSign';
const KDA_REQUEST_ACCOUNT = 'kda_requestAccount';
const KDA_GET_SELECTED_ACCOUNT = 'kda_getSelectedAccount';

//ERROR messages
const K_ACCOUNT_ONLY_ERROR = "For security, only support k: accounts";

//Google sheet field names 
const SHEETS_WALLET_ADDRESS_LABEL = "Wallet address";

//Backend API
const BACKEND_BASE_URL = "http://127.0.0.1:8000/"

export {
    BACKEND_BASE_URL,
    DEFAULT_GAS_LIMIT,
    S_TO_MS_MULTIPLIER,
    POLL_INTERVAL_S,
    KDA_CONNECT,
    SCREEN_NAMES,
    KDA_GET_CHAIN,
    KDA_DISCONNECT,
    KDA_GET_NETWORK,
    KDA_CHECK_STATUS,
    KDA_REQUEST_SIGN,
    KDA_REQUEST_ACCOUNT,
    KDA_GET_SELECTED_ACCOUNT,
    DEFAULT_CHAIN_ID,
    DEFAULT_GAS_PRICE,
    LOCAL_ACCOUNT_KEY,
    LOCAL_ACCOUNT_BALANCE_KEY,
    NETWORK_ID,
    LOCAL_CHAIN_ID,
    MAINNET_NETWORK_ID,
    TESTNET_NETWORK_ID,
    USER_KADCAR_GALLERY_LABEL,
    ALL_KADCAR_GALLERY_LABEL,
    KADCAR_NFT_COLLECTION,
    ADMIN_ADDRESS,
    IS_X_WALLET_KEY,
    X_WALLET_INSTALLED,
    DEFAULT_REQUEST_HEADERS,
    POST_METHOD,
    GET_METHOD,
    KADCAR_NFT_OPTIONS,
    REGEX_FOR_NFT_ID,
    TWITTER_URL,
    DISCORD_URL,
    FLUX_LABS_URL,
    MEDIUM_URL,
    MUSTANG_KNIGHTS_URL,
    K_ACCOUNT_ONLY_ERROR,
    SHEETS_WALLET_ADDRESS_LABEL,
    KADCARS_FACTORY,
    NEW_ADMIN_ADDRESS,
    IPFS_URL_PREFIX,
    IPFS_URL_SUFFIX,
    ALL_WEBP_URLS,
    USER_WEBP_URLS,
    USER_GLB_URLS,
    DEFAULT_POLLING_TIMER,
    KADCAR_TEST_COLLECTION,
    STICKER_LOCATIONS,
    KDA_QUICK_SIGN,
    UNIVERSAL_LEDGER,
    KADCARS_NFT_POLICY,
    DEFAULT_TTL
}