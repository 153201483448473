import {
    Box,
    Flex,
    HStack,
    useDisclosure,
    Stack,
    Icon,
    Image,
    Link,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { HiBars3BottomRight } from "react-icons/hi2";
import PagerContainer from "./PagerContainer";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { useState } from "react";
import { BsTwitterX } from "react-icons/bs";
import Logo from "../assets/iso/logo.png";

const Links = [
    { id: 2, label: "R2R", href: "#r2r" },
    { id: 1, label: "Game", href: "#game" },
    { id: 3, label: "How to play", href: "#mint" },
    { id: 4, label: "Team", href: "#team" },
    {
        id: 5,
        label: "Contact",
        href: "https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        page: true,
    },
    {
        id: 6,
        icon: BsTwitterX,
        href: "https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",

        page: true,
    },
    {
        id: 7,
        icon: FaDiscord,
        href: "http://discord.gg/74znq8jM8a",

        page: true,
    },
];

const NavLink = ({ item }) => {
    return (
        <>
            {!item?.page ? (
                <Link
                    // px={2}
                    // py={1}
                    rounded={"md"}
                    _hover={{
                        textDecoration: "none",
                        color: "#e37c3e",
                    }}
                    href={item?.href}
                    // target={item.icon ? "_blank" : "unset"}
                    color="white"
                    fontSize={["20px"]}
                >
                    {item?.label}
                    {item?.icon && <Icon as={item?.icon} />}
                </Link>
            ) : (
                <Link
                    // px={2}
                    // py={1}
                    rounded={"md"}
                    _hover={{
                        textDecoration: "none",
                        color: "#e37c3e",
                    }}
                    href={item?.href}
                    target="_blank"
                    color="white"
                    fontSize={["20px"]}
                >
                    {item?.label}
                    {item?.icon && <Icon as={item?.icon} />}
                </Link>
            )}
        </>
    );
};

export default function IsokoNav() {
    const [isBoxVisible, setIsBoxVisible] = useState(false);
    const handleToggleBox = () => {
        setIsBoxVisible(!isBoxVisible);
    };
    return (
        <Box style={{ zIndex: "2000" }}>
            <PagerContainer>
                <Box
                    bg={["none", null, "rgba(66, 66, 66, 0.20)"]}
                    px={["0px", "12px"]}
                    maxW="102rem"
                    mx="auto"
                    right={["16px", null, "32px", "3.44rem"]}
                    left={["16px", null, "32px", "3.44rem"]}
                    top={["16px", null, "3rem"]}
                    rounded="1.25rem"
                    py="20px"
                    pos="fixed"
                    className="xlZindex"
                >
                    <Box pos="relative">
                        <Flex
                            // h={16}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Box>
                                <Image
                                    w="54px"
                                    h="68px"
                                    src={Logo}
                                    alt="Logo image"
                                />
                            </Box>

                            <HStack
                                as={"nav"}
                                spacing={"1.7rem"}
                                display={{ base: "none", md: "flex" }}
                            >
                                {Links.map((item) => (
                                    <NavLink key={item?.id} item={item} />
                                ))}
                            </HStack>

                            <Icon
                                as={
                                    isBoxVisible
                                        ? IoMdClose
                                        : HiBars3BottomRight
                                }
                                aria-label={"Open Menu"}
                                display={{ md: "none" }}
                                onClick={handleToggleBox}
                                color="white"
                                boxSize="1.5rem"
                                cursor="pointer"
                            />
                        </Flex>
                        {/* Mobile nav */}
                        {/* {isBoxVisible && ( */}
                        <div>
                            <Box
                                px={["32px", "2.25rem"]}
                                py="1.25rem"
                                display={{ md: "none" }}
                                pos="absolute"
                                top="6rem"
                                w="100%"
                                rounded={"1.25rem"}
                                bgColor="#282624"
                                right={isBoxVisible ? "0rem" : "-760px"}
                                className="animate"
                            >
                                <Stack
                                    as={"nav"}
                                    spacing={4}
                                    className="animate"
                                    onClick={handleToggleBox}
                                >
                                    {MobileData.map((item) => (
                                        <NavLink key={item.id} item={item} />
                                    ))}

                                    <Flex gap="1.75rem" align="center">
                                        <Link
                                            target="_blank"
                                            href="https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                                        >
                                            <Icon
                                                as={FaTwitter}
                                                boxSize={"33px"}
                                                color="white"
                                            />
                                        </Link>

                                        <Link
                                            target="_blank"
                                            href="http://discord.gg/74znq8jM8a"
                                        >
                                            <Icon
                                                as={FaDiscord}
                                                boxSize={"46px"}
                                                color="white"
                                            />
                                        </Link>
                                    </Flex>
                                </Stack>
                            </Box>
                        </div>
                        {/* )} */}
                    </Box>
                </Box>

                {/* <Box p={4}>Main Content Here</Box> */}
            </PagerContainer>
        </Box>
    );
}

const MobileData = [
    { id: 2, label: "R2R", href: "#r2r" },
    { id: 1, label: "Game", href: "#game" },
    { id: 3, label: "How to play", href: "#mint" },
    { id: 4, label: "Team", href: "#team" },
    {
        id: 5,
        label: "Contact",
        href: "https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        page: true,
    },
    // {
    //     id: 6,
    //     icon: BsTwitterX,
    //     href: "https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    // },
    // { id: 7, icon: FaDiscord, href: "http://discord.gg/74znq8jM8a" },
];
