import { Box } from "@chakra-ui/react";
import React from "react";

const PagerContainer = ({ children }) => {
    return (
        <Box maxW="102rem" mx="auto" w="100%">
            <Box px={["16px", null, "32px", "55px"]}>{children}</Box>
        </Box>
    );
};

export default PagerContainer;
