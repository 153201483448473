import { useContext, useState } from "react"
import { KadcarCardExpanded } from "./kadcarCard/KadcarCard";
import { KadcarGarageContext } from "./KadcarGarageContextProvider";
import { GLTFModel } from "../../graphics/SceneUtils";
// import GLTF from '../../assets/models/untitled.glb'
// import GLTF from '../../assets/models/separate.gltf'
// import FBX from '../../assets/models/ww.fbx'
import GLTF from '../../assets/models/embedded.gltf'
// import GLTF from '../../assets/models/k2.glb'
import { BaseCanvas } from "../../graphics/BaseCanvas";
import { CardImageWrapper } from "./kadcarCard/KadcarCardStyles";
import { IPFS_URL_PREFIX, IPFS_URL_SUFFIX } from "../../utils/Constants";
import Image from "../elements/Image";
import Button from "../elements/Button";
import classNames from "classnames";
import ButtonGroup from "../elements/ButtonGroup";
import { UpgradeModal } from "../../upgradeHandling/UpgradeModal";
import { TransferNftModal } from "../../walletInteractions/TransferModal";

const StatSection = ({ name, children }) => {
    return (
        <div style={statBoxSection}>
            <span style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginTop: '18px', fontWeight: 'bold', color: "#f854e4" }}>{name}</span>
            {children}
        </div>
    )
}

const StatRow = ({ name, value }) => {

    return (
        <li >
            <span style={statNameTextSpan}> {name}</span>&nbsp;
            <span style={statValueTextSpan}> {value}</span>
        </li>
    )
}

const KadcarPreview = () => {
    const { selectedKadcar } = useContext(KadcarGarageContext);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    return (
        <div style={{ width: '100vw', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', position: 'absolute' }}>
            <div
                style={{
                    width: '100vw',
                    height: '100%',
                    position: 'absolute',
                    backgroundColor: 'black',
                    opacity: '45%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    zIndex: '998'
                }}
            />

            <KadcarCardExpanded kadcarNft={selectedKadcar}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '80%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        marginLeft: '20px',
                        marginRight: '20px'
                    }}>
                    {/* <BaseCanvas cameraController={true}> */}
                    {/* <Model> */}
                    {/* <FBXModel fbxModelPath={FBX} scale={1} /> */}
                    {/* <GLTFModel gltfModelPath={GLTF} scale={100} /> */}
                    {/* </Model> */}
                    {/* </BaseCanvas> */}
                    <img
                        // src={IPFS_URL_PREFIX + "/ipfs/" + selectedKadcar.uri['data'].split('//')[1]}
                        src={"https://" + selectedKadcar.uri['data'].split('//')[1] + IPFS_URL_SUFFIX}
                        style={{ objectFit: "contain", height: '97%' }}
                    />
                    {/* <span style={{fontWeight: 'bold', fontSize: '30', marginTop: '7px'}}>3D Preview Mode Coming Soon</span> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ height: '90%', width: '1px', backgroundColor: 'whitesmoke', opacity: '50%' }} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '32%',
                        height: '95%',
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginLeft: '20px',
                        marginRight: '10px',

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '70%',
                            overflowY: 'scroll'
                        }}>


                        <span style={{
                            textAlign: 'center',
                            marginLeft: '10px',
                            marginTop: '20px',
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "lightgray"
                        }}>
                            Kadcar Information:
                        </span>
                        <StatSection name={"Body"}>
                            <div style={statRow}>
                                <ul>
                                    <StatRow name={"Type:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][0]['val']} />
                                    <StatRow name={"Finish:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][1]['val']['id']} />
                                    <StatRow name={"Trim:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][2]['val']['id']} />
                                    <StatRow name={"Headlight Panels:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][6]['val']['id']} />
                                    <StatRow name={"Seats:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][7]['val']['id']} />
                                    <StatRow name={"Headlights:"} value={selectedKadcar.data[0]['datum']['components'][0]['stats'][9]['val']['id']} />
                                </ul>
                            </div>
                        </StatSection>
                        <StatSection name={"Performance Stats"}>
                            <div style={statRow}>
                                <ul>
                                    <StatRow name={"Top Speed:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][0]['val']['value'].toFixed(3) + " kmph"} />
                                    <StatRow name={"Acceleration:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][1]['val']['value'] + " s"} />
                                    <StatRow name={"Weight:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][2]['val']['value'] + " kg"} />
                                    <StatRow name={"Aerodynamicity:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][3]['val']['value'] + " %"} />
                                    <StatRow name={"Downforce:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][4]['val']['value'] + " %"} />
                                    <StatRow name={"Handling:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][5]['val']['value'] + " %"} />
                                    <StatRow name={"Horse-Power:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][6]['val']['value'] + " hp"} />
                                    <StatRow name={"Braking-Power:"} value={selectedKadcar.data[0]['datum']['components'][4]['stats'][7]['val']['value'] + " %"} />
                                </ul>
                            </div>
                        </StatSection>
                        <StatSection name={"Wheels"}>
                            <div style={statRow}>
                                <ul>
                                    <StatRow name={"Tires:"} value={selectedKadcar.data[0]['datum']['components'][1]['stats'][0]['val']} />
                                    <StatRow name={"Rims:"} value={selectedKadcar.data[0]['datum']['components'][1]['stats'][1]['val']} />
                                    <StatRow name={"Rim Finish:"} value={selectedKadcar.data[0]['datum']['components'][1]['stats'][3]['val']['id']} />
                                </ul>
                            </div>
                        </StatSection>
                        {
                            selectedKadcar?.data[1]['datum']['vehicle-information']['model'] === "K:2 Diamond Back" ?
                                <StatSection name={"Clearance Lights"}>
                                    <div style={statRow}>
                                        <ul>
                                            <StatRow name={"Type:"} value={selectedKadcar.data[0]['datum']['components'][6]['stats'][0]['val']} />
                                        </ul>
                                    </div>
                                </StatSection> :
                                <StatSection name={"Spoiler"}>
                                    <div style={statRow}>
                                        <ul>
                                            <StatRow name={"Type:"} value={selectedKadcar.data[0]['datum']['components'][6]['stats'][0]['val']} />
                                        </ul>
                                    </div>
                                </StatSection>
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '30%',
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ height: '1px', width: '95%', backgroundColor: 'whitesmoke', opacity: '50%' }} />
                        </div>
                        <span style={{
                            textAlign: 'center',
                            marginLeft: '10px',
                            marginTop: '15px',
                            marginBottom: '15px',
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "lightgray"
                        }}>
                            Actions:
                        </span>
                        {/* <div>
                            <Button
                                tag="a"
                                color="dark"
                                wideMobile
                                className={"buttons"}
                                onClick={() => setShowUpgradeModal(true)}
                            >
                                Upgrade
                            </Button>
                        </div> */}
                        <div>
                            <Button
                                tag="a"
                                color="dark"
                                wideMobile
                                className={"buttons"}
                                onClick={() => setShowTransferModal(true)}
                            >
                                Transfer
                            </Button>
                        </div>
                    </div>
                </div>
                { 
                    showUpgradeModal && (
                        <UpgradeModal
                            show={showUpgradeModal}
                            setShow={setShowUpgradeModal}
                            selectedKadcar={selectedKadcar.data[1]['datum']['vehicle-information']['model']}
                        />
                    ) 
                }
                {
                    showTransferModal && (
                        <TransferNftModal
                            show={showTransferModal}
                            setShow={setShowTransferModal}
                            nftId={"Kadcars#" + selectedKadcar.data[1]['datum']['vehicle-information']['model']+ ":" + selectedKadcar.data[1]['datum']['vehicle-information']['vin']}
                        />
                    )
                }
            </KadcarCardExpanded>
        </div>

    )
}

const statBoxSection = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
}

const statNameTextSpan = {
    alignSelf: 'flex-start',
    fontSize: '14px',
    color: 'lightgray',
    // color: "#f854e4",
    fontWeight: 'bold',
}

const statValueTextSpan = {
    alignSelf: 'center',
    fontSize: '14px',
}

const statRow = {
    width: '85%',
    alignSelf: 'flex-end',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left'
}

export {
    KadcarPreview
}