import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "../components/elements/Button";
import Modal from "../components/elements/Modal";
import { connectKadena, disconnectKadena, getKadenaConnectStatus, requestKadenaAccount } from "../kadenaInteraction/KadenaApi";
import { PactContext } from "../pact/PactContextProvider";
import { getAccounts, openZelcore } from "../utils/ZelcoreUtils";
import { toast } from "react-toastify";
import { trySaveLocal } from "../utils/utils";
import { DEFAULT_CHAIN_ID, IS_X_WALLET_KEY, NETWORK_ID } from "../utils/Constants";
import { useCheckForXWalletExtension } from "../hooks/BrowserExtensionHooks";
import { fetchAccountDetails, getAccountTokenBalance } from "../pact/PactUtils";
import { throttle } from "throttle-debounce";

const WalletOptionsModal = ({ show, setShow }) => {
    const xWalletExtensionInstalled = useCheckForXWalletExtension();
    const { account, netId, setIsXwallet, setConnectedWallet } = useContext(PactContext);
    const [zelcoreWalletOpts, setZelcoreWalletOps] = useState(null);
    const [zelcoreOpened, setZelcoreWalletOpened] = useState(false);
    const [errorGettingAccounts, setErrorGettingAccounts] = useState(false);
    const [chainweaverModal, setChainweaverModal] = useState(false);
    const [chainweaverWalletAddress, setChainweaverWalletAddress] = useState(null);
    const [localAccount, setLocalAccount] = useState(account);
    const [fetchedAccountMessage, setFetchedAccountMessage] = useState(null);

    async function handleWalletOptModalClose() {

        setZelcoreWalletOpened(false);
        setZelcoreWalletOps(null);
        setShow(false);
    }

    async function handleWalletButtonClick(isXwallet) {
        if (!xWalletExtensionInstalled) {
            toast.error("Please Install eckoWALLET Chrome Extension");
        }
        if (window.kadena) {
            setIsXwallet(true);
            trySaveLocal(IS_X_WALLET_KEY, true);
            await setConnectedWallet(handleWalletOptModalClose, isXwallet);
            // getKadenaConnectStatus(netId)
            // requestKadenaAccount(netId)
        }
    }

    function openZelcoreDesktop() {
        openZelcore();
        setZelcoreWalletOpened(true);
    }

    async function getZelcoreAccounts() {
        // toast.info("Open Zelcore on your Device");
        await openZelcore();
        setZelcoreWalletOpened(true);
        const { res, pRes } = await getAccounts(); //TODO: move in setconnectedwallet

        if (res.statusText === "Not Found") {
            setZelcoreWalletOpened(false);
            setErrorGettingAccounts(true);
            toast.error("Error retrieving accounts from Zelcore server");
            return;
        }

        if (pRes.status === "success") {
            // setZelcoreWalletOps(pRes.data);
            await handleZelcoreWalletAddressSelect(pRes.data);
        }

        handleWalletOptModalClose();
    }

    async function handleZelcoreWalletAddressSelect(walletAddresses) {
        await setConnectedWallet(
            handleWalletOptModalClose,
            false,
            walletAddresses[0]
        );
    }

    function connectChainweaverWallet() {
        setChainweaverModal(true);
    }

    async function handleChainWeaverWalletConnect(walletAddress) {
        if (localAccount) {
            await setConnectedWallet(
                handleWalletOptModalClose,
                false,
                localAccount.account
            );
        } else {
            toast.dismiss();
            toast.error("Failed to Connect to Account " + chainweaverWalletAddress);
        }
    }

    const onAccountNameChange = throttle(500, async (e) => {
        const accountName = e.target.value;
        !localAccount && setFetchedAccountMessage("");
        setLocalAccount(null);
        const tempAccount = await getAccountTokenBalance("coin", NETWORK_ID, accountName);
        console.log(tempAccount)
        if (tempAccount == null) {

        } else {
            setLocalAccount(tempAccount);
        }
    });

    return (
        <Modal show={show} handleClose={handleWalletOptModalClose}>
            <Container style={modalStyles}>
                {!zelcoreOpened && !chainweaverModal ? (
                    <>
                        <div>Please select the type of wallet to connect</div>
                        <Button
                            onClick={() => handleWalletButtonClick(true)}
                            style={{ color: "black" }}
                            className="buttons"
                        >
                            eckoWALLET
                        </Button>
                        <Button
                            style={{ color: "black" }}
                            className="buttons"
                            onClick={() => getZelcoreAccounts()}
                        >
                            Zelcore
                        </Button>
                        <Button
                            style={{ color: "black" }}
                            className="buttons"
                            onClick={() => connectChainweaverWallet()}
                        >
                            Chainweaver
                        </Button>
                    </>
                ) : (
                    <>
                        {
                            zelcoreOpened &&
                            <>
                                {zelcoreWalletOpts === null ? (
                                    <>
                                        {errorGettingAccounts ? (
                                            <>
                                                <div>
                                                    Open Zelcore and turn on the server
                                                </div>
                                                <Button onClick={getZelcoreAccounts}>
                                                    Try again
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    Log into Zelcore, Then click below
                                                    to connect your Kadena account
                                                </div>
                                                <Button onClick={getZelcoreAccounts}>
                                                    Connect Account
                                                </Button>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {zelcoreWalletOpts &&
                                            zelcoreWalletOpts.map((wallet) => {
                                                return (
                                                    <Button
                                                        onClick={() => {
                                                            handleZelcoreWalletAddressSelect(
                                                                wallet
                                                            );
                                                        }}
                                                        key={wallet}
                                                    >
                                                        {wallet.slice(0, 10)}...
                                                        {wallet.slice(
                                                            wallet.length - 3,
                                                            wallet.length
                                                        )}
                                                    </Button>
                                                );
                                            })}
                                    </>
                                )}
                            </>

                        }
                        {
                            chainweaverModal &&
                            <div style={modal2Styles}>
                                <div style={{ alignSelf: "center", marginBottom: "20px" }}>
                                    <span style={{ fontSize: "24px", fontWeight: "bold", color: "lightgray" }}>Enter Your K-Wallet Address</span>
                                </div>
                                <div style={{ alignSelf: "center", marginBottom: "5px" }}>
                                    <span style={{ fontSize: "20px", color: "lightgray" }}> {fetchedAccountMessage}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '30px', height: '40px' }}>
                                    <span style={{fontSize: '18px', alignSelf:'center'}}>Wallet:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type={'text'} placeholder={"e.g. k:1234..."} onChange={onAccountNameChange} />
                                </div>
                                    <Button onClick={() => handleChainWeaverWalletConnect(chainweaverWalletAddress)} >
                                        Connect Wallet
                                    </Button>
                            </div>
                        }
                    </>
                )}
            </Container>
        </Modal>
    );
};

const modalStyles = {
    width: "100%",
    // height: "30vh",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
};

const modal2Styles = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    // backgroundColor: 'green'
}

export { WalletOptionsModal };
