import React, { useContext, useState, useEffect } from "react";
import {
    useCreateTokenContract,
    useGetAccountRecordsInfo,
    useGetListOfAvailableTokens,
    useGetListOfMintedTokens,
    useGetMyKadcars,
    useGetTokenManifestContract,
} from "../../pact/PactContractHooks";
import { NETWORK_ID, SCREEN_NAMES } from "../../utils/Constants";
import { KadcarGameContext } from "../kadcarcomponents/KadcarGameContextProvider";
import { MainHeaderScreenContainer } from "../kadcarcomponents/KadcarComponents";
import { useCheckForXWalletExtension } from "../../hooks/BrowserExtensionHooks";
import { TransferNftModal } from "../../walletInteractions/TransferModal";
import { MintModal } from "../../walletInteractions/MintModal";
import { PactContext } from "../../pact/PactContextProvider";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Modal from "../elements/Modal";
import classNames from "classnames";
import { WalletModal } from "../../walletInteractions/WalletModal";
import { useNavigate } from "react-router-dom";
import { KadcarGarageContext } from "../kadcarcomponents/KadcarGarageContextProvider";
import { KadcarPreview } from "../kadcarcomponents/KadcarPreview";
import { WalletOptionsModal } from "../../walletInteractions/WalletOptionsModal";
import {
    getKadenaConnectStatus,
    quickSign,
    requestKadenaAccount,
} from "../../kadenaInteraction/KadenaApi";
import { useDetectAccountSwap } from "../../hooks/KadenaCustomHooks";
import { getAccounts } from "../../utils/ZelcoreUtils";
import { defineMetaData, getAccountTokenBalance, getNetworkUrl, readFromContract } from "../../pact/PactUtils";
import CountdownTimer from "../../LatestMintPage/CountdownTimer";
import { Image } from "@chakra-ui/react";
import Logo from "../../assets/images/logoLatest.png";
import FooterSocial from "../layout/partials/FooterSocial";
import ProgressBar from "../ProgressBar";
import ProgressBarSection from "../ProgressBar";
import { toast } from "react-toastify";
import { AiFillCloseSquare } from "react-icons/ai";
import axios from "axios";
import { b64urlEncodeArr, hashBin } from "../../utils/EncodingUtils";

const propTypes = {
    ...SectionProps.types,
};

const defaultProps = {
    ...SectionProps.defaults,
};

const KadcarHub = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    //Get PactContext, KadcarGarageContext, and KadcarGameContext
    const pactContext = useContext(PactContext);
    const kadcarGameContext = useContext(KadcarGameContext);
    const kadcarGarageContext = useContext(KadcarGarageContext);

    /**********************************************************/
    /**********************************************************/

    /**********************************************************/
    /******************* CUSTOM HOOK CALLS ********************/
    /**********************************************************/
    const extensionInstalled = useCheckForXWalletExtension();
    const currentUserKadcarNfts = useGetMyKadcars();
    const getMintedTokenSupply = useGetListOfMintedTokens();
    const getAccountRecordsInfo = useGetAccountRecordsInfo();
    const createK2Tokens = useCreateTokenContract();

    const getMintedTokens = useGetListOfMintedTokens();
    const getNonMintedTokens = useGetListOfAvailableTokens();
    const getManifests = useGetTokenManifestContract();

    //Wallet, mint, and trasnfer modal controls
    const [showMintModal, setShowMintModal] = useState(false);
    const [showMintError, setShowMintError] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showWalletNameModal, setShowWalletNameModal] = useState(false);
    const [showWalletOptModal, setShowWalletOptModal] = useState(false);

    const [showPassModal, setShowPassModal] = useState(false);
    const [uploadFolder, setUploadFolder] = useState(false);
    const [pass, setPass] = useState("");
    const navigate = useNavigate();

    const [mdList, setMdList] = useState();

    const outerClasses = classNames(
        // "hero section center-content",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "hero-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    useEffect(() => {
        kadcarGameContext.setCurrentScreen(null);
        getKadenaConnectStatus(NETWORK_ID);
        kadcarGameContext.setLoadingNfts(true);
    }, []);

    //Disconnect the user's account from the app
    function disconnectKadenaAccount() {
        //Call the API function to disconnect this user's wallet from the app
        // disconnectKadena(pactContext);
        pactContext.logoutAccount();
    }

    //Display all this user's kadcars
    async function displayCurrentUserKadcars() {
        getKadenaConnectStatus(NETWORK_ID);
        // console.log(currentUserKadcarNfts)
        // kadcarGameContext.setMyKadcars(currentUserKadcarNfts[1]);
        // kadcarGameContext.setMyKadcarIds(currentUserKadcarNfts[0]);
        kadcarGameContext.setCurrentScreen(SCREEN_NAMES.MY_KADCARS);
    }

    function handleHomeButtonClick() {
        kadcarGameContext.setCurrentScreen(null);
        navigate("/");
    }

    async function testClick(e) {
        // var res = await getAccountRecordsInfo();
        // console.log(res)
        // var res = await createK2Tokens()
        setUploadFolder(true);
    }

    async function upload(event) {
        let list = [];
        const fileList = Array.from(event.target.files);

        for (var i = 0; i < fileList.length; i++) {
            let fileName = fileList[i].name.split(".")[0];
            const fileReader = new FileReader();
            fileReader.onloadend = async (e) => {
                // console.log(fileName)
                // console.log(fileReader.result)
                list.push({
                    name: fileName,
                    md: fileReader.result,
                });
                // await createK2Tokens(fileReader.result);
                setMdList(list);
            };
            await fileReader.readAsText(fileList[i]);
        }
    }

    async function handleOpenMintModal() {
        if (pactContext.account) {
            let toastID = toast.loading("Fetching data from block chain");

            const tokenList = await getMintedTokenSupply();
            const accountInfo = await getAccountRecordsInfo();

            toast.dismiss(toastID);
            if (accountInfo === null) {
                toast.error("Failed to retrieve account info on " + NETWORK_ID);
                return;
            }

            kadcarGameContext.setCurrentMintedTokens(tokenList?.result?.data);
            pactContext.setAccountRecordInfo(accountInfo);
            setShowMintModal(true);
        } else {
            setShowWalletNameModal(true);
        }
    }

    function handleOpenTransferModal() {
        if (pactContext.account) {
            setShowTransferModal(true);
        } else {
            setShowWalletNameModal(true);
        }
    }

    function handleHomeButtonClick() {
        kadcarGameContext.setCurrentScreen(null);
        navigate("/");
    }

    function displayAllMintedKadcars() {
        kadcarGameContext.setCurrentScreen(SCREEN_NAMES.ALL_KADCARS);
    }

    
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    async function testClick() {
        // const minted = await getMintedTokenSupply();
        // const nonMinted = await getNonMintedTokens();

        // let ids = nonMinted.concat(minted.result.data)

        // let webps = {}
        // let glbs = {}

        // for (var i = 0; i < ids.length; i++) {
        //     let manifests = await getManifests(ids[i])
        //     let webpcid = manifests.uri["data"].split('//')[1]
        //     let glbcid = manifests.data[2]["datum"]["art-asset"]["data"].split('//')[1]
            
        //     if (webpcid) {
        //         webps[ids[i]] = webpcid;
        //     } else {
        //         webps[ids[i]] = "null";
        //     }

        //     if (glbcid) {
        //         glbs[ids[i]] = glbcid;
        //     } else {
        //         glbs[ids[i]] = "null";
        //     }

        //     // webps.push(manifests.uri["data"].split('//')[1])
        //     // glbs.push(manifests.data[2]["datum"]["art-asset"]["data"].split('//')[1])
            
        //     await sleep(500)
        //     console.log(manifests) 
        //     console.log(webps)
        //     console.log(glbs)
        // }
        // var def = pactContext.defaultMeta();
        // console.log(def)
        // var res = await readFromContract({
        //     pactCode: `(free.kadena-mining-club.get-all-on-sale)`,
        //     meta: defineMetaData(def.chainId, def.gasPrice, 250000),
        // }, getNetworkUrl(NETWORK_ID));
        // console.log(res)
        // var res = await axios.get("http://127.0.0.1:8000/r2r/testing/")
        // console.log(res)

        let gasPrice = 0.00000001
        let chainId = "1"
        let jsonc = "{\"networkId\":\"testnet04\",\"payload\":{\"cont\":{\"proof\":null,\"data\":{\"kc\":{\"pred\":\"keys-all\",\"keys\":[\"f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5\"]},\"transformation-list\":[{\"transform\":{\"obj\":{\"uri\":{\"data\":\"view-references\",\"scheme\":\"ipfs\"},\"new-datum\":{\"hash\":\"6Te22fUzf-9ynFfzYTYnKLzVVU2JhqhimJPgYGyuAwQ\",\"uri\":{\"data\":\"view-references\",\"scheme\":\"pact:schema\"},\"datum\":{\"art-asset\":{\"data\":\"ipfs://bafybeielzyapofnglxicaith7etxczpxq3psaeq6uh7chuh6dtbbmtqyny\",\"scheme\":\"ipfs://\"}}}},\"type\":\"replace\"}},{\"transform\":{\"obj\":{\"hash\":\"6Te22fUzf-9ynFfzYTYnKLzVVU2JhqhimJPgYGyuAwQ\",\"uri\":{\"data\":\"nft-references\",\"scheme\":\"pact:schema\"},\"datum\":{\"test\":\"test\"}},\"type\":\"add\"}},{\"transform\":{\"obj\":{\"uri\":{\"data\":\"ipfs://bafybeia3obqfvgnxpm56oan2b7mempewuml4xynlcgkodks6tf44b3hnie\",\"scheme\":\"ipfs\"}},\"type\":\"uri\"}}]},\"pactId\":\"DycORcKohpEWlyC6VxX9pcT28y_bP-G52xjlGqAaUVc\",\"rollback\":false,\"step\":2}},\"signers\":[{\"pubKey\":\"b9b798dd046eccd4d2c42c18445859c62c199a8d673b8c1bf7afcfca6a6a81e3\"}],\"meta\":{\"creationTime\":1678004406,\"ttl\":6000,\"gasLimit\":150000,\"chainId\":\"1\",\"gasPrice\":1.0e-8,\"sender\":\"k:b9b798dd046eccd4d2c42c18445859c62c199a8d673b8c1bf7afcfca6a6a81e3\"},\"nonce\":\"\\\"2023-03-05 08:20:06.599883 UTC\\\"\"}"

        let payload = {
            payload: JSON.parse(jsonc)["payload"]
        }
        let meta = defineMetaData();
        meta["sender"] = pactContext.account.account
        console.log(payload)
        const cmd = {
            meta,
            payload: JSON.parse(jsonc)["payload"],
            signers: [{pubKey: pactContext.accountBalance.guard.keys[0]}],
            networkId: "testnet04",
            nonce: "123"
        }

        let hashbin = hashBin(jsonc)
        console.log(hashbin)
        console.log(Buffer.from(hashbin).toString('hex'))
        let hash = b64urlEncodeArr(hashbin)
        console.log(hash)
        // let res = await quickSign(cmd, pactContext.accountBalance.guard.keys[0])
    }

    return (
        <>
            <section
                {...props}
                className={`${outerClasses} hero_bg`}
                style={
                    {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                    }
                }
            >
                <div className="">
                    {/* NEw Nav Bar  */}
                    <div
                        className="maxW newNav "
                        style={{ position: "relative" }}
                    >
                        <div>
                            <Image src={Logo} alt="Logo" width="200px" />
                        </div>
                        <FooterSocial />
                    </div>
                    {/* {New Nav bar End } */}

                    <div style={{ width: "360px", margin: " 0px auto " }}>
                        <ProgressBarSection />
                    </div>

                    <div
                        className={`${innerClasses} contentWeight maxW topShift`}
                    >
                        <div className="hero-content width_ctr center_test">
                            <h1
                                className="mt-0 mb-16 reveal-from-bottom text_width_ctr"
                                data-reveal-delay="200"
                            >
                                Build the Ultimate{" "}
                                <span className="text-color-primary">
                                    Kadcar
                                </span>
                                !
                            </h1>
                            <div className="" style={{ maxWidth: "500px" }}>
                                <p
                                    className="m-0 mb-32 reveal-from-bottom"
                                    data-reveal-delay="400"
                                >
                                    Kadena's{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            color: "#f56be7",
                                        }}
                                    >
                                        {" "}
                                        1<sup>st</sup>
                                    </span>{" "}
                                    car NFT where owners can
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            color: "#f56be7",
                                        }}
                                    >
                                        {" "}
                                        collect
                                    </span>
                                    ,
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            color: "#f56be7",
                                        }}
                                    >
                                        {" "}
                                        upgrade
                                    </span>{" "}
                                    &
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            color: "#f56be7",
                                        }}
                                    >
                                        {" "}
                                        race
                                    </span>{" "}
                                    their Kadcars
                                </p>

                                <CountdownTimer />

                                <div
                                    style={{
                                        marginBottom: "20px",
                                        marginTop: "10px",
                                        maxWidth: "500px",
                                    }}
                                >
                                    Start by connecting your Wallet to explore Kadcars
                                </div>

                                <div
                                // className="reveal-from-bottom"
                                // data-reveal-delay="600"
                                >
                                    {kadcarGameContext.currentScreen ===
                                        null && (
                                            <ButtonGroup>
                                                { 
                                                    !pactContext.account ?
                                                        <Button
                                                            onClick={ () => setShowWalletOptModal(true) }
                                                            tag="a"
                                                            color="primary"
                                                            wideMobile
                                                            className={"buttons"}
                                                        >
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            Connect Wallet
                                                        </Button> :
                                                        <Button
                                                            onClick={
                                                                disconnectKadenaAccount
                                                            }
                                                            tag="a"
                                                            color="primary"
                                                            wideMobile
                                                            className="buttons"
                                                        >
                                                            Disconnect Wallet
                                                        </Button>
                                                    }
                                                <Button
                                                    onClick={handleOpenMintModal}
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    className="buttons"
                                                >
                                                    Mint Kadcar
                                                </Button>
                                                <Button
                                                    onClick={
                                                        displayCurrentUserKadcars
                                                    }
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    className="buttons"
                                                >
                                                    Your Kadcars
                                                </Button>
                                                {/* <Button
                                                    onClick={
                                                        handleOpenTransferModal
                                                    }
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    className="buttons"
                                                >
                                                    Transfer
                                                </Button> */}
                                                {/* <Button
                                                    onClick={
                                                        displayAllMintedKadcars
                                                    }
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    className="buttons"
                                                >
                                                    All Kadcars
                                                </Button> */}
                                                {/* <Button onClick={testClick} tag="a" color="dark" wideMobile>
                                                      test
                                                </Button> */}
                                            </ButtonGroup>
                                        )}
                                    {(kadcarGameContext.currentScreen ===
                                        SCREEN_NAMES.MY_KADCARS ||
                                        kadcarGameContext.currentScreen ===
                                            SCREEN_NAMES.ALL_KADCARS) && (
                                        <ButtonGroup>
                                            <Button
                                                onClick={() =>
                                                    handleHomeButtonClick()
                                                }
                                                tag="a"
                                                color="primary"
                                                wideMobile
                                                className="buttons"
                                            >
                                                Back to Home
                                            </Button>
                                            {/* <Button
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    disabled={true}
                                                    className="buttons"
                                                >
                                                    Garage
                                                </Button> */}
                                            <Button
                                                onClick={handleOpenMintModal}
                                                tag="a"
                                                color="dark"
                                                wideMobile
                                                className="buttons"
                                            >
                                                Mint Kadcar
                                            </Button>
                                            {/* <Button
                                                    onClick={
                                                        handleOpenTransferModal
                                                    }
                                                    tag="a"
                                                    color="dark"
                                                    wideMobile
                                                    className="buttons"
                                                >
                                                    Transfer
                                                </Button> */}
                                        </ButtonGroup>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="width_ctr">
                            <MainHeaderScreenContainer />
                        </div>
                        <Modal
                            show={showPassModal}
                            handleClose={() => setShowPassModal(false)}
                        >
                            <label>
                                <input
                                    type={"text"}
                                    value={pass}
                                    onChange={(event) => {
                                        setPass(event.target.value);
                                    }}
                                />
                                <Button>Enter</Button>
                            </label>
                        </Modal>

                        {showWalletOptModal && (
                            <WalletOptionsModal
                                show={showWalletOptModal}
                                setShow={setShowWalletOptModal}
                            />
                        )}
                        {/* {
              showWalletNameModal &&
              <WalletModal show={showWalletNameModal} setShow={setShowWalletNameModal} isXwallet={extensionInstalled} />
            } */}
                        {showTransferModal && (
                            <TransferNftModal
                                show={showTransferModal}
                                setShow={setShowTransferModal}
                            />
                        )}
                        {showMintModal && (
                            <MintModal
                                show={showMintModal}
                                setShow={setShowMintModal}
                                showMintError={showMintError}
                                setShowMintError={setShowMintError}
                            />
                        )}
                    </div>
                </div>
            {
                kadcarGarageContext.selectedKadcar ? <KadcarPreview /> : null
            }
            </section>
        </>
    );
};

KadcarHub.propTypes = propTypes;
KadcarHub.defaultProps = defaultProps;

export default KadcarHub;
