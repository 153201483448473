import Pact from "pact-lang-api";
import { toast } from 'react-toastify';
import { 
    ADMIN_ADDRESS, 
    DEFAULT_CHAIN_ID, 
    KADCAR_NFT_COLLECTION, 
    NETWORK_ID, 
    TESTNET_NETWORK_ID, 
    MAINNET_NETWORK_ID,
    NEW_ADMIN_ADDRESS, 
    DEFAULT_GAS_PRICE, 
    LOCAL_CHAIN_ID, 
    DEFAULT_GAS_LIMIT,
    DEFAULT_TTL
} from '../utils/Constants';
import { creationTime, wait } from '../utils/utils';
import { S_TO_MS_MULTIPLIER, POLL_INTERVAL_S } from "../utils/Constants";
import { useEffect, useState } from "react";

//Module names on Kadena
const UNIVERSAL_LEDGER = "free.universal-ledger";
const KADCARS_FACTORY = "free.kadcar-factory";
// const KADCARS_FACTORY = "free.kadcars-factory";
const KADCARS_NFT_POLICY = "free.kadcars-nft-policy";
// const KADCARS_NFT_POLICY = "free.kadcars-ledger-policy";
const KADCARS_STICKER_POLICY = "free.kadcar-sticker-policy.get-stickers-by-owner"

// returns object containing default env data for transaction
function getDefaultEnvData(account, accountBalance) {
    const defaultEnvData = {
        "user-ks": accountBalance.guard,
        account: accountBalance.account,
    }

    return defaultEnvData;
}

// returns array of default capabilities for minting
function getDefaultMintCapabilities(tokenIds=[], account, amount, priceToPay, /*sender, receiver*/) {
    var tokenCaps = tokenIds.map((tokenId) => {
        var cap = Pact.lang.mkCap(
            "Mint Capability",
            "This capability allows users to mint",
            `${UNIVERSAL_LEDGER}.MINT`,
            [tokenId, account.account, 1.0]
        );
        return cap;
    });

    const defaultMintCaps = [
        // Pact.lang.mkCap(
        //     "Reconciler",
        //     "For accounting via events",
        //     `${UNIVERSAL_LEDGER}.RECONCILE`,
        //     [tokenId, amount, sender, receiver]
        // ),
        ...tokenCaps,
        Pact.lang.mkCap(
            "Transfer capability",
            "To complete token transfer",
            "coin.TRANSFER",
            [account.account, NEW_ADMIN_ADDRESS, priceToPay]
        ),
        Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", [])
    ]

    return defaultMintCaps;
}

//Function to execute given pact command
async function executePactContract(pactContextObject, pactCmd, poll=false) {
    const pactCode = pactCmd; 
    const meta = pactContextObject.metaData(DEFAULT_GAS_LIMIT);
    // const contractOutput = await pactContextObject.readFromContract({ pactCode, meta });
    const networkUrl = getNetworkUrl(NETWORK_ID);
    const contractOutput = await readFromContract({ pactCode, meta }, networkUrl, "Failed to Execute Pact Contract", poll);
    return contractOutput;
}

/********************************** Contracts ******************************************/
//TODO: cleanup
//Get pact command, env data and capabilities to create token
function getCommandToCreateToken(account, metadataFile) {
    /***** Build Env Data *****/
    // var envData = {
    //     ...getDefaultEnvData(account, accountBalance),
    //     token_spec: {
    //         "fungible": {
    //             "refName" : {
    //                 "namespace": null,
    //                 "name":'coin',
    //             },
    //             "refSpec":[{
    //                 "namespace":null,
    //                 "name":"fungible-v2"
    //             }]
    //         },
    //         // "creator": "creator",
    //         // 'creator-guard': {"keys": ["creator"], "pred": "keys-all"},
    //         "creator": account.account,
    //         'creator-guard': account.guard,
    //         'mint-guard': {"keys": ["mint"], "pred": "keys-all"},
    //         'royalty-rate': 0.03,
    //         'max-supply': 1.0,
    //         'min-amount': 1.0,
    //         owner: "creator",
    //         'latest-spec': {}
    //     }
    // }

    // updateTokenEnvDataByType(type, payload, envData);

    var envData = JSON.parse(metadataFile);
    envData["specs"] = envData["specs"].map((spec) => {
        spec["vehicle_spec"]["vehicle-information"]["vin"] = spec["vehicle_spec"]["vehicle-information"]["vin"].toString();
        return spec
    })

    /***** Build Capabilities *****/
    var caps = [
        // Pact.lang.mkCap(`Pay to manufacture`, "Pay to manufacture", `coin.TRANSFER`, [
        //     "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5",
        //     ADMIN_ADDRESS,
        // ]),
        // Pact.lang.mkCap(
        //     "Verify your account",
        //     "Verify your account",
        //     `free.${KADCAR_NFT_COLLECTION}.ACCOUNT_GUARD`,
        //     ["k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5"]
        // ),
        Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
    ]

    /***** Get Contract Call *****/
    var contractCall = `(${KADCARS_FACTORY}.create-k2s-bulk)`;

    // switch(type) {
    //     case "Kadcars":
    //         contractCall = `(${KADCARS_FACTORY}.create-${payload.modelName})`
    //         break;
    //     default:
    //         break;
    // }
    
    return [contractCall, caps, envData];
}

//TODO: needs to pull from firebase BEFORE MINT
function getPactCommandToRetrieveAvailableTokens(collectionId) {
    return `(${KADCARS_NFT_POLICY}.get-non-minted-tokens-for-collection "${collectionId}")`;
}

function getPactCommandToRetrieveMintedTokens(collectionId) {
    return `(${KADCARS_NFT_POLICY}.get-minted-tokens-for-collection "${collectionId}")`;
}

function getPactCommandToRetrieveTokensByUserId(collectionId, account) {
    return `(${KADCARS_NFT_POLICY}.get-cars-in-collection-by-owner "${collectionId}" "${account.account}")`;
}

//Get pact command to retrieve manifest for specified model
function getPactCommandToRetrieveTokenManifest(tokenId) {
    return `(${UNIVERSAL_LEDGER}.get-manifest "${tokenId}")`;
}

function getPactCommandToRetrieveAcountRecordsInfo(account) {
    return `(${KADCARS_NFT_POLICY}.get-account-whitelist-info "${account}")`;
}

function getPactCommandToRetrieveStickerByOwner(account) {
    return `(${KADCARS_STICKER_POLICY}.get-stickers-by-owner "${account}")`;
}

function getPactCommandToTransferKadcars(nftId, account, receiver) {
    const envData = {
        "user-ks": {
            keys: [receiver.split(":")[1]],
            pred: "keys-all"
        },
        account: receiver,
    }

    const caps = [
        Pact.lang.mkCap(
            "Transfer Capability",
            "transfer",
            `${UNIVERSAL_LEDGER}.TRANSFER`, [nftId, account, receiver, 1.0]
        ),
        Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
    ]

    const pactCmd = `(${UNIVERSAL_LEDGER}.transfer-create "${nftId}" "${account}" "${receiver}" (read-keyset "user-ks") 1.0)`

    return [pactCmd, caps, envData];
}

//TODO: switch to only using accountBalance, and rename accountBalance state
function getPactCommandToAttachStickerToKadcar(kadcarId, stickerId, account, accountBalance) {
    const envData = {
        ...getDefaultEnvData(account, accountBalance)
    };

    const caps = [
        Pact.lang.mkCap(
            "Transfer cap", 
            "transfer", 
            `${UNIVERSAL_LEDGER}.TRANSFER`, [stickerId, account.account, "m:free.kadcars-factory:sticker-escrow", 1.0]),
        Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
        Pact.lang.mkCap("Owns All", "owns all", "free.kadcars-factory.OWNS-ALL", [account.account, kadcarId, stickerId])
    ];

    const pactCmd = `(${KADCARS_FACTORY}.attach-sticker "${kadcarId}" "${stickerId}" "${account}")`;
    
    return [pactCmd, caps, envData];
}

//Get pact command, env data and capabilities to mint kadcar from universal ledger
function getPactCommandToMintNftWithUniversalLedger(tokenId, account, accountBalance, amount, priceToPay) {
    const envData = {
        ...getDefaultEnvData(account, accountBalance),
    };

    const caps = [
        ...getDefaultMintCapabilities(tokenId, account, amount, priceToPay)
    ];

    // const pactCmd = `(${UNIVERSAL_LEDGER}.mint "${tokenId}" "${account.account}" ${accountBalance.guard} ${amount})`;
    const pactCmd = `(${UNIVERSAL_LEDGER}.mint "${tokenId[0]}" "${account.account}" (read-keyset "user-ks") 1.0)`;

    return [pactCmd, caps, envData];
}

function getCommandToUpgradeNft(account, accountBalance, tokenId, upgradeTokenId) {
    var envData = {
        ...getDefaultEnvData(account, accountBalance)
    }

    var caps = [
        ...getDefaultMintCapabilities()
    ];

    return [``, caps, envData];
}

function getKMCCommand() {
    return `kadena-mining-club.get-all-on-sale`
}

/********************************** Kadcars Factory Contracts ******************************************/

function getCommandToBulkMintWithKadcarsFactory(tokenList, account, accountBalance, priceToPay) {
    const envData = {
        ...getDefaultEnvData(account, accountBalance),
        "token-list": tokenList
    };

    const caps = [
        ...getDefaultMintCapabilities(tokenList, account, tokenList.length, priceToPay)
    ];

    // const pactCmd = `(${KADCARS_FACTORY}.mint-bulk "${account.account}" (read-keyset "user-ks"))`;
    const pactCmd = `(${UNIVERSAL_LEDGER}.mint-atrium-bulk ${tokenList} "${account.account}" (read-keyset "user-ks"))`;

    return [pactCmd, caps, envData];
}

/****************************************************************************************/
/*********************************** OLD CONTRACTS! *************************************/
/****************************************************************************************/

//Retrieve the Pact command to get NFTs for given owner
function getPactCommandForNftsByOwner(account) {
    return `(free.kadcars-nft-collection.get-kadcars-for-owner "${account}")`;
}

//Retrieve the Pact command to for NFT using given ID
function getPactCommandForNftByNftId(nftId) {
    return `(free.kadcars-nft-collection.get-kadcar-for-nft-id "${nftId}")`;
}

//Retrieve the Pact command to get all minted Kadcars
function getPactCommandForAllNfts() {
    return `(free.kadcars-nft-collection.get-kadcars)`;
}

//DEPRECATED
//Retrieve the Pact command to Mint new NFT ID
function getPactCommandForMintingNft(account) {
    return `(free.kadcars-nft-collection.manufacture-k1 "${account}" 1)`;
}

//DEPRECATED
//Retrieve the Pact command to Mint new NFT ID
function getPactCommandForTransferNft(nftId, sender, receiver) {
    return `(free.${KADCAR_NFT_COLLECTION}.transfer "${nftId}" "${sender}" "${receiver}")`;
}

//Retrieve the Pact command to Mint new NFT ID
// function getPactCommandForMintingNft(account, model) {
//     return `(free.kadcars-nft-collection.manufacture-${model} "${account}" 1)`;
// }


/***********************************************************************************/
/*********************************** Utilities *************************************/
/***********************************************************************************/

//Add payload to envdata based on type of token specified
function updateTokenEnvDataByType(type, payload, envData) {
    var env = null;
    
    switch(type) {
        case "Kadcar":
            env = {
                ...envData,
                vehicle_spec: payload
            }
            break;
        default:
            break;
    }

    return env;
}

//Get the URL using the provided network ID
function getNetworkUrl(netId) {
    if (netId == null) {
        return;
    }
    if (netId === TESTNET_NETWORK_ID) {
        return `https://api.testnet.chainweb.com/chainweb/0.0/${TESTNET_NETWORK_ID}/chain/${DEFAULT_CHAIN_ID}/pact`;
    } else if (netId === MAINNET_NETWORK_ID) {
        return `https://api.chainweb.com/chainweb/0.0/${MAINNET_NETWORK_ID}/chain/${DEFAULT_CHAIN_ID}/pact`;
    }
    throw new Error("networkId must be testnet, please select testnet in your eckoWALLET");
}

const getAccountTokenBalance = async (coinCode, networkId, account) => {

    if (account) {
        const res = await readFromContract({
            pactCode: `(${coinCode}.details ${JSON.stringify(account)})`,
            meta: defineMetaData(DEFAULT_CHAIN_ID, DEFAULT_GAS_PRICE, 150000)
        }, getNetworkUrl(networkId), "Error retrieving balance for this wallet");
        return res;
    } else {
        toast.error("Invalid account, cannot fetch balance for account: " + account);
        return { result: { status: 'failure' } };
    }
  };

const defineMetaData = (chainId, gasPrice, gasLimit) => {
    return Pact.lang.mkMeta(
        "",
        chainId ? chainId : DEFAULT_CHAIN_ID,
        gasPrice ? gasPrice : DEFAULT_GAS_PRICE,
        gasLimit ?? 150000,
        creationTime(),
        600
    );
};

const fetchAccountDetails = async (metaData) => {
    return await readFromContract({
        pactCode: `(coin.details ${JSON.stringify(metaData.account)})`,
        meta: defineMetaData(metaData.chainId, metaData.gasPrice, metaData.gasLimit),
    }, getNetworkUrl(NETWORK_ID));
};

const readFromContract = async (cmd, networkUrl, returnError, poll=false) => {
    try {
        console.log(cmd)
        let data = await Pact.fetch.local(cmd, networkUrl);
        
        console.log(data)
        if (data?.result?.status === "success") {
            if (poll) {
                return data;
            }
            return data.result.data;
        } else {
            if (returnError === true) {
                return data?.result?.error?.message;
            } else {
                return null;
            }
        }
    } catch (e) {
        toast.error("Had trouble fetching data from the blockchain");
        console.log(e);
    }
    return null;
};

const pollForTransaction = async (requestKey, displayResult=false) => {
    var networkUrl = getNetworkUrl(NETWORK_ID);
    var pollingDuration = 0;
    var pollRes= null;

    while (pollingDuration < 240) {
        // await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
        await wait(1 * S_TO_MS_MULTIPLIER);
        try {
            pollRes = await Pact.fetch.poll(
                { requestKeys: [requestKey] },
                networkUrl
            );
        } catch (e) {
            console.log(e);
            toast.error("Attempting transaction update again...");
            continue;
        }
        if (Object.keys(pollRes).length !== 0) {
            break;
        }
        pollingDuration += POLL_INTERVAL_S;
    }

    if (pollRes[requestKey].result.status === "success") {
        return pollRes[requestKey];
    } else {
        console.log("error in polling")
    }
}

function assembleCommand(pactCode,
    caps,
    envData,
    sender,
    gasLimit=DEFAULT_GAS_LIMIT,
    gasPrice=DEFAULT_GAS_PRICE,
    chainId=DEFAULT_CHAIN_ID,
    ttl=DEFAULT_TTL,
    signingPubKey,
    networkId
) {
    const cmd = {
        pactCode,
        caps: caps,
        envData: envData,
        sender: sender,
        gasLimit: gasLimit,
        gasPrice,
        chainId,
        ttl,
        signingPubKey: signingPubKey,
        networkId: networkId,
    }

    return cmd;
}

export {
    assembleCommand,
    getNetworkUrl,
    defineMetaData,
    readFromContract,
    pollForTransaction,
    fetchAccountDetails,
    executePactContract,
    getAccountTokenBalance,
    getCommandToUpgradeNft,
    getCommandToCreateToken,
    getPactCommandForAllNfts,
    getPactCommandForMintingNft,
    getPactCommandForNftByNftId,
    getPactCommandForNftsByOwner,
    getPactCommandForTransferNft,
    getPactCommandToRetrieveTokenManifest,
    getCommandToBulkMintWithKadcarsFactory,
    getPactCommandToRetrieveTokensByUserId,
    getPactCommandToRetrieveAvailableTokens,
    getPactCommandToMintNftWithUniversalLedger,
    getPactCommandToRetrieveAcountRecordsInfo,
    getPactCommandToRetrieveMintedTokens,
    getPactCommandToRetrieveStickerByOwner,
    getPactCommandToAttachStickerToKadcar,
    getPactCommandToTransferKadcars
}