import React from "react";
import { KadcarCard } from "./KadcarComponents";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "./styles.css";

import { Pagination } from "swiper";

const KadcarCardGrid = ({ kadcars, kadcarIds, pages, page, setPage, disconnected }) => {
    // const extraStyle = { overflowY: "scroll" };
    // const centerColumnStyle = {
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    // };

    // function CustomMessage({ text }) {
    //     return <p style={{ textAlign: "center" }}>{text}</p>;
    // }

    return (
        // <CenterColumn centerColumnStyle={centerColumnStyle} extraStyle={extraStyle}>
        // <div
        //     style={{
        //         display: "flex",
        //         flexDirection: "row",
        //         justifyContent: "center",
        //         width: "100%",
        //         height: "100%",
        //         // flexWrap: "wrap",
        //     }}
        // >
        <div style={{ width: "800px" }}>
            <Swiper
                // slidesPerView={3}
                // spaceBetween={30}
                // centeredSlides={true}
                modules={[Pagination]}
                grid={{
                    rows: 1,
                }}
                pagination={{
                    type: "fraction",
                }}
                style={{
                    width: "100%",
                    height: "600px",
                    display: "flex",
                }}
                breakpoints={{
                    320: {
                        width: 320,
                        spaceBetween: 5,
                        slidesPerView: 1,
                        centeredSlides: true,
                    },
                    420: {
                        width: 420,
                        spaceBetween: 5,
                        slidesPerView: 1,
                        centeredSlides: true,
                    },
                    768: {
                        width: 768,
                        spaceBetween: 5,
                        slidesPerView: 2,
                        centeredSlides: true,
                    },
                    1024: {
                        width: 1024,
                        spaceBetween: 5,
                        slidesPerView: 2,
                        centeredSlides: true,
                    },
                }}
            >
                {
                    (kadcars === null || kadcars?.length === 0) ?
                        <SwiperSlide>
                            <KadcarCard blank={true} />
                        </SwiperSlide>
                        :
                        <>
                            {kadcars.map((kadcar, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <KadcarCard kadcarNft={kadcar} index={index} />
                                    </SwiperSlide>
                                );
                            })}
                        </>
                }
            </Swiper>
        </div>

        // </div>
        //</CenterColumn>
    );
};

export { KadcarCardGrid };
