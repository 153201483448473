import { useContext, useEffect } from "react";
import { PactContext } from "../pact/PactContextProvider";
import { X_WALLET_INSTALLED } from "../utils/Constants";
import { trySaveLocal } from "../utils/utils";

function useCheckForXWalletExtension() {
    const { xWalletInstalled, setXwalletInstalled } = useContext(PactContext)

    useEffect(() => {
        setXwalletInstalled(window?.kadena?.isKadena === true);
        trySaveLocal(X_WALLET_INSTALLED, xWalletInstalled);
    }, []);

    return xWalletInstalled;
}

export {
    useCheckForXWalletExtension
}