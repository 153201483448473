import React, { useCallback, useState, useEffect, createContext } from "react";
import Pact from "pact-lang-api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    DEFAULT_CHAIN_ID,
    DEFAULT_GAS_PRICE,
    DEFAULT_REQUEST_HEADERS,
    IS_X_WALLET_KEY,
    K_ACCOUNT_ONLY_ERROR,
    LOCAL_ACCOUNT_BALANCE_KEY,
    LOCAL_ACCOUNT_KEY,
    LOCAL_CHAIN_ID,
    MAINNET_NETWORK_ID,
    NETWORK_ID,
    POLL_INTERVAL_S,
    POST_METHOD,
    S_TO_MS_MULTIPLIER,
    TESTNET_NETWORK_ID,
    USER_GLB_URLS,
    USER_WEBP_URLS,
} from "../utils/Constants";
import { creationTime, parseResponse, confirmTransactionWithNetwork, tryLoadLocal, trySaveLocal, wait, checkXwalletNetworkAndChainSettings, checkIfNullOrUndefined } from "../utils/utils";
import { getNetworkUrl, getAccountTokenBalance } from "./PactUtils";
import { connectKadena, disconnectKadena, getKadenaConnectStatus, requestKadenaAccount, requestSign } from "../kadenaInteraction/KadenaApi";
import getAccounts from "../utils/ZelcoreUtils";

export const PactContext = createContext(); //Define Pact Context

const PactContextProvider = ({ children }) => {
    // const [chainId, setChainId] = useState(() => tryLoadLocal(LOCAL_CHAIN_ID));
    const [chainId, setChainId] = useState(DEFAULT_CHAIN_ID);
    const [gasPrice, setGasPrice] = useState(DEFAULT_GAS_PRICE);
    const [netId, setNetId] = useState(NETWORK_ID);
    const [account, setAccount] = useState(() => tryLoadLocal(LOCAL_ACCOUNT_KEY));
    const [accountRecordInfo, setAccountRecordInfo] = useState(null);
    const [accountBalance, setAccountBalance] = useState(() => tryLoadLocal(LOCAL_ACCOUNT_BALANCE_KEY));
    const [networkUrl, setNetworkUrl] = useState(null);
    const [currTransactionState, setCurrTransactionState] = useState({});
    const [isXwallet, setIsXwallet] = useState(tryLoadLocal(IS_X_WALLET_KEY));
    const [xWalletInstalled, setXwalletInstalled] = useState(false);

    useEffect(() => {
        account && updateAccountBalanceData(account.account);
    }, []);

    useEffect(() => {
        setNetworkUrl(getNetworkUrl(netId));
        getKadenaConnectStatus(netId)
    }, [netId]);

    const executeTransaction = useCallback(() => {
        if (!checkIfNullOrUndefined(currTransactionState.cmdToConfirm) && currTransactionState.shouldSign === true) {
            signTransaction(currTransactionState.cmdToConfirm);
        }
    }, [currTransactionState]);

    useEffect(() => {
        executeTransaction();
    }, [executeTransaction]);

    const clearTransaction = () => {
        setCurrTransactionState({});
    };

    const setNetworkSettings = (netId, chainId, gasPrice) => {
        setNetId(netId);
        setChainId(chainId);
        setGasPrice(gasPrice);
    };

    const useSetNetworkSettings = (netId, chainId, gasPrice = DEFAULT_GAS_PRICE) => {
        useEffect(() => {
            setNetworkSettings(netId, chainId, gasPrice)
        }, [netId, chainId, gasPrice]);
    }

    const fetchAccountDetails = async (accountName) => {
        return await readFromContract({
            pactCode: `(coin.details ${JSON.stringify(accountName)})`,
            meta: defaultMeta(),
        });
    };

    const updateTransactionState = (newParams) => {
        const { transactionMessage, successCallback } = { currTransactionState };
        successCallback && successCallback();
        setCurrTransactionState({
            transactionMessage,
            successCallback,
            ...newParams,
        });
    };

    const defaultMeta = (gasLimit) => {
        return Pact.lang.mkMeta(
            "",
            chainId,
            gasPrice,
            gasLimit ?? 150000,
            creationTime(),
            600
        );
    };

    //TODO: make this not state dependent
    const sendTransaction = async (
        cmd,
        previewComponent = null,
        transactionMessage = null,
        successCallback = () => {},
        errorHandler = () => {},
        errorToHandle,
        shouldSign
    ) => {
        setCurrTransactionState({
            transactionMessage,
            successCallback,
            errorHandler,
            cmdToConfirm: cmd,
            previewComponent,
            errorToHandle,
            shouldSign: shouldSign
        });
    };

    const logoutAccount = async () => {
        if (isXwallet) {
            await disconnectKadena(netId);
            setIsXwallet(false);
        }
        trySaveLocal(LOCAL_ACCOUNT_KEY, null);
        trySaveLocal(IS_X_WALLET_KEY, false);
        trySaveLocal(LOCAL_ACCOUNT_BALANCE_KEY, null);
        trySaveLocal(USER_WEBP_URLS, null);
        trySaveLocal(USER_GLB_URLS, null);
        setAccount(null);
        // setIsXwallet(false);
        // setIsConnectWallet(false);
    };

    const readFromContract = async (cmd, returnError) => {
        try {
            let data = await Pact.fetch.local(cmd, networkUrl);
            if (data?.result?.status === "success") {
                return data.result.data;
            } else {
                if (returnError === true) {
                    return data?.result?.error?.message;
                } else {
                    return null;
                }
            }
        } catch (e) {
            toast.error("Had trouble fetching data from the blockchain");
            console.log(e);
        }
        return null;
    };

    const setConnectedWallet = async (callback, isXwallet, accountWallet=null) => {
        console.log(isXwallet);
        if (isXwallet) {
            var res_account = null;
            try {
                // await disconnectKadena(netId);
                const res = await connectKadena(netId);
                console.log(res)
                if (res.status !== "success") {
                    if (netId !== NETWORK_ID) {
                        toast.error(`Could not connect to eckoWALLET, please select ${NETWORK_ID}`);
                    } 
                    // closeConnectWallet();
                    return;
                }

                if (res.account?.account === account?.account) {
                    toast.error('This wallet is already connected and active');
                    return;
                }

                if (res.status === "success") {
                    res_account = res.account;

                    setAccount(res_account);

                    toast.success(`Connected ${res_account.account.slice(0, 10)}...`, {
                        hideProgressBar: true,
                        autoClose: 2000,
                    });
                    trySaveLocal(LOCAL_ACCOUNT_KEY, res_account);

                    await updateAccountBalanceData(res_account.account);

                    callback && callback();
                }
            } catch (e) {
                console.log(e);
                toast.error("Couldn't connect to eckoWALLET");

                return;
            }
        } else {
            setIsXwallet(false);
            const acc = {
                account: accountWallet,
                chainId: DEFAULT_CHAIN_ID
            }
            setAccount(acc);
            setChainId(DEFAULT_CHAIN_ID);

            toast.success(`Connected ${accountWallet.slice(0, 10)}...`, {
                hideProgressBar: true,
                autoClose: 2000,
            });
            trySaveLocal(LOCAL_ACCOUNT_KEY, acc);

            await updateAccountBalanceData(accountWallet);

            callback && callback();
        }
    };

    const updateAccountBalanceData = async (accountName) => {
        const balanceData = await getAccountTokenBalance("coin", NETWORK_ID, accountName);
        console.log(balanceData)
        if (balanceData) {
            setAccountBalance(balanceData);
            trySaveLocal(LOCAL_ACCOUNT_BALANCE_KEY, balanceData);
        } else {
            let accountbalance = {
                "guard": {
                    "pred": "keys-all",
                    "keys": []
                },
                "balance": 0.0,
                "account": ""
            }
            setAccountBalance(accountbalance);
            trySaveLocal(LOCAL_ACCOUNT_BALANCE_KEY, accountbalance);
            // toast.error("Error getting account balance");
        }
    }

    const pollForTransaction = async (requestKey) => {
        let reqKeyPreview = requestKey.slice(0, 10);
        let time_spent_polling_s = 0;
        let pollRes = null;

        const { transactionMessage } = currTransactionState;
        let waitingText = (
            <span
                onClick={() =>
                    window.open(
                        `https://explorer.chainweb.com/mainnet/txdetail/${requestKey}`,
                        "_blank"
                    )
                }
            >
                {`Waiting ${POLL_INTERVAL_S}s for transaction ${reqKeyPreview}... (${transactionMessage})`}
            </span>
        );
        toast.info(waitingText, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            draggable: true,
            toastId: requestKey,
        });
        while (time_spent_polling_s < 600) {
            await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
            try {
                pollRes = await Pact.fetch.poll(
                    { requestKeys: [requestKey] },
                    networkUrl
                );
            } catch (e) {
                console.log(e);
                toast.error("Attempting transaction update again...");
                continue;
            }
            if (Object.keys(pollRes).length !== 0) {
                break;
            }
            time_spent_polling_s += POLL_INTERVAL_S;
            waitingText = `Waiting ${time_spent_polling_s + POLL_INTERVAL_S}s for transaction ${reqKeyPreview}... (${transactionMessage})`;
            toast.update(requestKey, { render: waitingText });
        }

        if (pollRes[requestKey].result.status === "success") {
            toast.update(requestKey, {
                render: `Transaction ${reqKeyPreview}... (${transactionMessage}) completed!`,
                type: "success",
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
            });
            if (currTransactionState?.successCallback != null) {
                currTransactionState.successCallback(pollRes[requestKey]);
            }
        } else {
            console.log(pollRes);
            if (currTransactionState?.errorHandler != null) {
                currTransactionState.errorHandler();
                return;
            }

            toast.error(
                `Transaction ${requestKey}... (${transactionMessage}) failed, please try again`,
                {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                }
            );
        }
        clearTransaction();
    };

    const signTransaction = async (cmdToSign) => {
        updateTransactionState({ signingCmd: cmdToSign, shouldSign: false });

        let signedCmd = null;
        if (isXwallet) {
            let xwalletSignRes = null;
            try {
                const accountConnectedRes = await requestKadenaAccount(netId, window.location.hostname);
                console.log(accountConnectedRes)
                if (accountConnectedRes?.status !== "success") {
                    const checkRes = await checkXwalletNetworkAndChainSettings();

                    if (checkRes === null) {
                        clearTransaction();
                        // toast.error("Please reconnect your eckoWALLET, also make sure testnet and chain ID 1 are selected.");
                        // logoutAccount();
                        return;
                    } else {
                        cmdToSign.chainId = checkRes.account.chainId;
                        updateTransactionState({ signingCmd: cmdToSign });
                    }
                } else if (accountConnectedRes?.wallet?.account !== account.account) {
                    toast.error(`Please select ${account.account} from your eckoWALLET extension`);
                    return;
                } 
                // else if (accountConnectedRes?.wallet?.chainId !== chainId && accountConnectedRes?.wallet?.chainId !== parseInt(chainId)) {
                //     toast.error(`Please make sure you select chain ${chainId} in the eckoWALLET extension`);
                //     return;
                // }
                const dataToSign = {
                    networkId: netId,
                    signingCmd: cmdToSign
                };
                xwalletSignRes = await requestSign(netId, dataToSign);
                console.log(dataToSign)
                console.log(xwalletSignRes)
            } catch (e) {
                console.log(e);
            }
            if (xwalletSignRes.status !== "success") {
                toast.error("Command could not be signed in eckoWALLET");
                clearTransaction();
                return;
            }
            signedCmd = xwalletSignRes.signedCmd;
        } else {
            try {
                console.log(cmdToSign)
                signedCmd = await Pact.wallet.sign(cmdToSign);
            } catch (e) {
                console.log(e);
                toast.error("Command could not be signed in wallet");
                clearTransaction();
                return;
            }
        }
        
        updateTransactionState({ signedCmd });
        console.log(currTransactionState)
        
        let localRes = null;
        localRes = await confirmTransactionWithNetwork(networkUrl, POST_METHOD, DEFAULT_REQUEST_HEADERS, signedCmd);
        console.log(localRes);

        const parsedLocalRes = await parseResponse(localRes);
        console.log(parsedLocalRes);
        // return;
        if (parsedLocalRes?.result?.status === "success") {
            let data = null;
            try {
                data = await Pact.wallet.sendSigned(signedCmd, networkUrl);
            } catch (e) {
                console.log(e);
                console.log(parsedLocalRes)
                if (parsedLocalRes?.result?.error?.message.includes(currTransactionState?.errorToHandle)) {
                    clearTransaction();
                    currTransactionState?.errorHandler && currTransactionState.errorHandler();
                    return;
                }
                toast.error("Sending transaction to blockchain failed, please make sure the command format is correct");
                clearTransaction();
                return;
            }
            console.log(data);
            const requestKey = data.requestKeys[0];
            updateTransactionState({
                sentCmd: signedCmd,
                requestKey,
            });
            console.log(currTransactionState)
            await pollForTransaction(requestKey);
        } else {
            console.log(parsedLocalRes);
            if (parsedLocalRes?.result?.error?.message === K_ACCOUNT_ONLY_ERROR) {
                toast.error(`Failed to confirm transaction: only "k" accounts supported for security`, {
                    hideProgressBar: true,
                });
            } else {
                if (parsedLocalRes?.result?.error?.message.includes(currTransactionState?.errorToHandle)) {
                    clearTransaction();
                    currTransactionState?.errorHandler && currTransactionState.errorHandler();
                    return;
                }

                //TODO: error callback here
                toast.error(`Transaction Failed: ${parsedLocalRes?.result?.error?.message}`, {
                    hideProgressBar: true,
                });

            }
            clearTransaction();
            return;
        }
    };

    return (
        <PactContext.Provider
            value={{
                netId,
                chainId,
                account,
                gasPrice,
                isXwallet,
                networkUrl,
                accountBalance,
                xWalletInstalled,
                currTransactionState,
                accountRecordInfo,
                setNetId,
                setChainId,
                setAccount,
                defaultMeta,
                setGasPrice,
                setIsXwallet,
                setNetworkUrl,
                sendTransaction,
                signTransaction,
                readFromContract,
                setAccountBalance,
                setNetworkSettings,
                useSetNetworkSettings,
                fetchAccountDetails,
                updateAccountBalanceData,
                setConnectedWallet,
                setXwalletInstalled,
                logoutAccount,
                clearTransaction,
                setAccountRecordInfo
            }}
        >
            <ToastContainer
                position="top-right"
                theme="dark"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {children}
        </PactContext.Provider>
    )
}

export {
    PactContextProvider
}