import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { KadcarGameContext } from "../components/kadcarcomponents/KadcarGameContextProvider";
import { ADMIN_ADDRESS, DEFAULT_GAS_LIMIT, IPFS_URL_PREFIX, KADCAR_NFT_COLLECTION, USER_WEBP_URLS, KADCAR_TEST_COLLECTION, DEFAULT_GAS_PRICE, DEFAULT_CHAIN_ID, DEFAULT_TTL } from "../utils/Constants";
import { checkIfNullOrUndefined, extractObjectFromGLB, formatAssetIpfsUrl, pickTokensRandomly, trySaveLocal } from "../utils/utils";
import { PactContext } from "./PactContextProvider";
import Pact from "pact-lang-api";
import { toast } from "react-toastify";
import { 
    executePactContract, 
    getPactCommandForAllNfts, 
    getPactCommandForMintingNft, 
    getPactCommandForTransferNft, 
    getPactCommandForNftsByOwner,
    getCommandToCreateToken, 
    getPactCommandToRetrieveTokenManifest,
    getCommandToUpgradeNft,
    getPactCommandToMintNftWithUniversalLedger,
    getPactCommandToRetrieveAvailableTokens,
    getCommandToBulkMintWithKadcarsFactory,
    getPactCommandToRetrieveTokensByUserId,
    getPactCommandToRetrieveAcountRecordsInfo,
    getPactCommandToRetrieveMintedTokens,
    getPactCommandToRetrieveStickerByOwner,
    getPactCommandToAttachStickerToKadcar,
    assembleCommand,
    getPactCommandToTransferKadcars
} from "./PactUtils";

async function executeContractForUser(parameters, getPactCommandFunction, invocableStateSetter=null) {
    var result = null;

    if (!checkIfNullOrUndefined(parameters.account) && 
        !checkIfNullOrUndefined(parameters.chainId) && 
        !checkIfNullOrUndefined(parameters.networkUrl)) {
        result = await executeContract(parameters, getPactCommandFunction, invocableStateSetter);
    }

    return result;
}

async function executeContract(parameters, getPactCommandFunction, invocableStateSetter = null) {
    if (!checkIfNullOrUndefined(parameters.chainId) &&
    !checkIfNullOrUndefined(parameters.networkUrl)) {
        const data = await executePactContract(parameters, getPactCommandFunction);

        invocableStateSetter && invocableStateSetter(data);
        return data;
    }
}

function useGetKadcarByNftId() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);

    return async (nftId) => {
        var parameters = {
            account: account.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }
        // executeContractForUser(parameters, getPactCommandForNftByNftId(nftId), setNewKadcarNft);
        // const result = await executePactContract(parameters, getPactCommandForNftByNftId(nftId));
        const result = await executePactContract(parameters, getPactCommandToRetrieveAvailableTokens(KADCAR_TEST_COLLECTION));

        return result;
    }
}

function useGetMyKadcarsFunction() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    const { myKadcars, setMyKadcars } = useContext(KadcarGameContext);

    return async () => {
        // const pactCode = `(free.kakars-nft-collection.get-owner "7")`; //TODO: MAKE CONSTANTS
        // const meta = defaultMeta(1000000);
        // const contractOutput = await readFromContract({ pactCode, meta });
        // return contractOutput;
        if (account) {
            var parameters = {
                account: account.account,
                chainId: chainId,
                metaData: defaultMeta,
                networkUrl: networkUrl,
                readFromContract: readFromContract
            }
            executeContractForUser(parameters, getPactCommandForNftsByOwner(account.account), setMyKadcars);
            return myKadcars;
        }
    };
}

function useDefpactFunction() {
    const { account, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    return async () => {
        const pactCode = `(free.testmultisign.test-send "k:63ae052e6f03abe50df6ba2208dc73003a43ab712bcc72207e62440a4bcf6ff9" "k:87301192e227fad48891bd9a6b40fed99e4cf69a04c0c5fc777b134849386aa9")`;

        const defaultMintCaps = [
            // Pact.lang.mkCap(
            //     "Reconciler",
            //     "For accounting via events",
            //     `${UNIVERSAL_LEDGER}.RECONCILE`,
            //     [tokenId, amount, sender, receiver]
            // ),
            // Pact.lang.mkCap(
            //     "Transfer capability",
            //     "To complete token transfer",
            //     "coin.TRANSFER",
            //     [account.account, NEW_ADMIN_ADDRESS, priceToPay]
            // ),
            Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", [])
        ]

        // const defaultEnvData = {
        //     "user-ks": account.guard,
        //     account: account.account,
        // }

        const cmd = {
            pactCode,
            caps: defaultMintCaps,
            sender: "k:63ae052e6f03abe50df6ba2208dc73003a43ab712bcc72207e62440a4bcf6ff9",
            gasLimit: 3000,
            gasPrice,
            chainId,
            ttl: 600,
            // envData: defaultEnvData,
            // signingPubKey: account.guard.keys[0],
            networkId: netId,
        };
        const result = await Pact.wallet.sign(cmd);
        return result;
    }
}

//Custom hook to retrieve all kadcars minted by user
function useGetMyKadcars(parameters) {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    const { myKadcars, myKadcarIds, setMyKadcars, setMyKadcarIds, setLoadingNfts, loadingNfts } = useContext(KadcarGameContext);
    const [currentUserKadcarNftIds, setCurrentUserKadcarNftIds] = useState(null); //TODO: MEMOIZE
    const [currentUserKadcarNftManifests, setCurrentUserKadcarNftManifests] = useState([]);
    const [activeManifest, setActiveManifest] = useState(null);
    
    //Retrieves Kadcar NFTs associated with the current user
    useEffect(() => {
        setMyKadcarIds(currentUserKadcarNftIds);
        fetchManifests(currentUserKadcarNftIds);
    }, [currentUserKadcarNftIds]);

    useEffect(() => {
        updateCurrentManifestList(activeManifest);
    }, [activeManifest]);

    useEffect(() => {
        if (currentUserKadcarNftIds?.length === currentUserKadcarNftManifests?.length) {
            setLoadingNfts(false);
        }
    }, [currentUserKadcarNftManifests])

    //Establish the parameters needed for the pact command to get the kadcar ids
    const getUserKadcarsCallback = useCallback(async () => {
        setCurrentUserKadcarNftManifests([]);
        if (account && loadingNfts) {
            var parameters = {
                account: account.account,
                chainId: chainId,
                metaData: defaultMeta,
                networkUrl: networkUrl,
                readFromContract: readFromContract
            }
            // executeContractForUser(parameters, getPactCommandForNftsByOwner(account.account), setCurrentUseKadcarNfts);
            let nftIds = await executeContractForUser(parameters, getPactCommandToRetrieveTokensByUserId(KADCAR_TEST_COLLECTION, account), setCurrentUserKadcarNftIds); //TODO: make collection name retrieval dynamic
            console.log(nftIds)
            if (nftIds?.length === 0 || nftIds === null) {
                setLoadingNfts(false);
            }
        } 
    }, [account, chainId, networkUrl, loadingNfts]);

    useEffect(() => {
        getUserKadcarsCallback();
    }, [getUserKadcarsCallback]);

    function updateCurrentManifestList(manifest) {
        if (manifest === null) {
            return;
        }

        setCurrentUserKadcarNftManifests([...currentUserKadcarNftManifests, manifest]);
        setMyKadcars([...currentUserKadcarNftManifests, manifest]);
    }

    async function fetchManifests(tokenIds) {
        if (tokenIds === null) {
            return;
        }
        var parameters = {
            account: account.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }

        let webpUrls = [];
        //TODO: make collection name retrieval dynamic
        for (const tokenId of tokenIds) {
            let manifest = await executeContractForUser(parameters, getPactCommandToRetrieveTokenManifest(tokenId['token-id']), setActiveManifest);

            webpUrls.push(IPFS_URL_PREFIX + "/ipfs/" + manifest?.uri["data"].split('//')[1]);
        }
        trySaveLocal(USER_WEBP_URLS, webpUrls)
    }

    // return [currentUserKadcarNftIds, currentUserKadcarNftManifests];
    return getUserKadcarsCallback;
}


function useGetAllKadcarsFunction() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    const { allKadcars, setAllKadcars } = useContext(KadcarGameContext);

    return async () => {
        // const pactCode = `(free.kakars-nft-collection.get-owner "7")`; //TODO: MAKE CONSTANTS
        // const meta = defaultMeta(1000000);
        // const contractOutput = await readFromContract({ pactCode, meta });
        // return contractOutput;
        if (account) {
            var parameters = {
                account: account.account,
                chainId: chainId,
                metaData: defaultMeta,
                networkUrl: networkUrl,
                readFromContract: readFromContract
            }
            executeContract(parameters, getPactCommandForAllNfts(), setAllKadcars);
            return allKadcars;
        }
    };
}

//Custom hook to retrieve all minted kadcars
function useGetAllKadcars() {
    const { chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    const { allKadcars, setAllKadcars } = useContext(KadcarGameContext);

    useEffect(() => {
        var parameters = {
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract,
        }

        executeContract(parameters, getPactCommandForAllNfts(), setAllKadcars);
    }, []);

    return allKadcars;
}

function useGetListOfAvailableTokens() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    // const [newKadcarNft, setNewKadcarNft] = useState(null);

    return async () => {
        var parameters = {
            account: account.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }

        //TODO: write function for executing generic pact contracts
        const result = await executePactContract(parameters, getPactCommandToRetrieveAvailableTokens(KADCAR_TEST_COLLECTION), true);

        const tokenList = result?.result?.data;
        return tokenList ? tokenList : [];
    }
}

function useGetListOfMintedTokens() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);
    // const [newKadcarNft, setNewKadcarNft] = useState(null);

    return async () => {
        var parameters = {
            account: account?.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }

        //TODO: write function for executing generic pact contracts
        const result = await executePactContract(parameters, getPactCommandToRetrieveMintedTokens(KADCAR_TEST_COLLECTION), true);

        return result;
    }
}
//TODO: add error handling for case of failed sticker retrieval
function useGetStickerByOwner() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);

    return async (callback=null) => {
        var parameters = {
            account: account?.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }

        //TODO: write function for executing generic pact contracts
        const result = await executePactContract(parameters, getPactCommandToRetrieveStickerByOwner(account.account), false);
        callback && callback(result?.result?.data);
        return result;
    }
}

function useGetAccountRecordsInfo() {
    const { account, chainId, networkUrl, readFromContract, defaultMeta } = useContext(PactContext);

    return async () => {
        var parameters = {
            account: account.account,
            chainId: chainId,
            metaData: defaultMeta,
            networkUrl: networkUrl,
            readFromContract: readFromContract
        }

        //TODO: write function for executing generic pact contracts
        const result = await executePactContract(parameters, getPactCommandToRetrieveAcountRecordsInfo(account.account), false);

        return result;
    }
}

//TODO: make generic hook for other upgrades
function useApplyStickerUpgradeContract() {
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    return async (kadcarId, stickerId, callback=null, errorHandler=null) => {
        const pactContractData = getPactCommandToAttachStickerToKadcar(kadcarId, stickerId, account.account);
        const pactCode = pactContractData[0];
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        
        //TODO: make function to assemble command
        const cmd = assembleCommand(pactCode, pactContractCaps, pactContractEnvData, account.account, DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE, DEFAULT_CHAIN_ID, DEFAULT_TTL, accountBalance.guard.keys[0], netId);

        const previewContent = (
            <p>Applying sticker: {stickerId} to Kadcar: {kadcarId}</p>
        );

        sendTransaction(
            cmd, 
            previewContent,
            `Applying sticker upgrade to Kadcar`,
            callback ?? (() => alert(`upgrade succeeded`)),
            errorHandler ?? null,
            "",
            false
        );
    }
}

//Returns function for executing contract to create token
function useCreateTokenContract() {
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    return (metadataFile, batchNumber, callback=null, errorHandler=null) => {
        const pactContractData = getCommandToCreateToken(account, metadataFile);
        const pactCode = pactContractData[0]; //make return code, caps, and envdata
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        const cmd = {
            pactCode,
            caps: pactContractCaps,
            sender: "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5",
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice,
            chainId,
            ttl: 600,
            envData: pactContractEnvData,
            signingPubKey: "f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5",
            networkId: netId,
        };
        const previewContent = (
            <p>Creating tokens</p>
        );

        sendTransaction(
            cmd,
            previewContent,
            `Creating 1`,
            callback ?? (() => alert(`${batchNumber} succeeded`)),
            errorHandler ?? null,
            "",
            true
        );
    }
}

//Returns function for minting nft 
function useMintNftContract() {
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);
    const { pricePerKadcar } = useContext(KadcarGameContext);
    const getTokensAvailableForMint = useGetListOfAvailableTokens();

    return async (amount=1, priceToPay, callback=null, errorHandler=null) => {
        const availableTokens = await getTokensAvailableForMint();
        if (availableTokens < amount) {
            toast.error(
                `Cannot mint ${amount}... only ${availableTokens.length} remaining`,
                {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                }
            );
            return null;
        }
        const tokensToMint = pickTokensRandomly(availableTokens, amount);

        const pactContractData = getPactCommandToMintNftWithUniversalLedger(tokensToMint, account, accountBalance, 1, priceToPay);
        const pactCode = pactContractData[0];
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        const cmd = {
            pactCode,
            caps: pactContractCaps,
            sender: account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice,
            chainId,
            ttl: 600,
            envData: pactContractEnvData,
            signingPubKey: accountBalance.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>Minting {amount} Kadcars for {priceToPay} KDA</p>
        );

        sendTransaction(
            cmd,
            previewContent,
            `Manufacturing ${amount}`,
            callback ?? (() => alert("Kadcar Manufactured!")),
            errorHandler ?? null,
            "TRANSFER exceeded",
            true
        );
    }
}

//Returns function for minting nft 
function useMintBulkWithKadcarsFactory() {
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);
    const { pricePerKadcar } = useContext(KadcarGameContext);
    const getTokensAvailableForMint = useGetListOfAvailableTokens();

    return async (amount=1, priceToPay, callback=null, errorHandler=null) => {
        const availableTokens = await getTokensAvailableForMint();
        if (availableTokens < amount) {
            toast.error(
                `Cannot mint ${amount}... only ${availableTokens.length} remaining`,
                {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                }
            );
            return null;
        }
        const tokensToMint = pickTokensRandomly(availableTokens, amount);

        const pactContractData = getCommandToBulkMintWithKadcarsFactory(tokensToMint, account, accountBalance, priceToPay);

        const pactCode = pactContractData[0];
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        const cmd = {
            pactCode,
            caps: pactContractCaps,
            sender: account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice,
            chainId,
            ttl: 600,
            envData: pactContractEnvData,
            signingPubKey: accountBalance.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>Minting {amount} Kadcars for {priceToPay * amount} KDA</p>
        );

        sendTransaction(
            cmd,
            previewContent,
            `Manufacturing ${amount}`,
            callback ?? (() => alert("Kadcar Manufactured!")),
            errorHandler ?? null,
            "TRANSFER exceeded",
            true
        );
    }
}

//Returns function for contract to retrieve token manifest
function useGetTokenManifestContract() {
    const { account, chainId, defaultMeta, networkUrl, readFromContract } = useContext(PactContext);

    return async (tokenId, callback=null) => {
        if (account) {
            var parameters = {
                account: account.account,
                chainId: chainId,
                metaData: defaultMeta,
                networkUrl: networkUrl,
                readFromContract: readFromContract
            }
            const tokenManifest = await executeContract(parameters, getPactCommandToRetrieveTokenManifest(tokenId), callback && callback);

            return tokenManifest;
        }
    }
}

//Returns function for contract to upgrade NFT
function useUpgradeNftContract() {
    const { account, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    return (tokenId, upgradeId, callback=null) => {
        const pactContractData = getCommandToUpgradeNft(account, tokenId, upgradeId);
        const pactCode = pactContractData[0];
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        const cmd = {
            pactCode,
            caps: pactContractCaps,
            sender: account.account,
            gasLimit: 3000,
            gasPrice,
            chainId,
            ttl: 600,
            envData: pactContractEnvData,
            signingPubKey: account.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>Getting manifest for token {tokenId}</p>
        );

        sendTransaction(
            cmd,
            previewContent,
            `Upgrading Manifest`,
            callback ?? (() => alert("NFT successfully upgraded!"))
        );
    }
}

function useTransferKadcarsWithUniversalLedger() {
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    return async (nftId, receiver, callback=null, errorHandler=null) => {
        const pactContractData = getPactCommandToTransferKadcars(nftId, account.account, receiver);
        const pactCode = pactContractData[0];
        const pactContractCaps = pactContractData[1];
        const pactContractEnvData = pactContractData[2];
        const cmd = {
            pactCode,
            caps: pactContractCaps,
            sender: account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice,
            chainId,
            ttl: 600,
            envData: pactContractEnvData,
            signingPubKey: accountBalance.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>Transferring token {nftId}</p>
        );
        sendTransaction(
            cmd,
            previewContent,
            `Transfering ${nftId}`,
            callback ?? (() => alert(`transfer succeeded`)),
            errorHandler ?? null,
            "",
            true
        );
    }
}

//Generic transaction builder and sender
function pactContractSender() {
    // const { account, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);

    // return (contract, payload, callback) => {
    //     const pactContractData = contract();
    //     const pactCode = pactContractData[0]; //make return code, caps, and envdata
    //     const pactContractCaps = pactContractData[1];
    //     const pactContractEnvData = pactContractData[2];
    //     const cmd = {
    //         pactCode,
    //         caps: pactContractCaps,
    //         sender: account.account,
    //         gasLimit: 3000, //make this dynamic
    //         gasPrice,
    //         chainId,
    //         ttl: 600, //figure this out + make this dynamic
    //         envData: pactContractEnvData,
    //         signingPubKey: account.guard.keys[0], //make sure this is always true
    //         networkId: netId,
    //     };
    //     const previewContent = (
    //         <p>{payload.previewContent}</p>
    //     );
    //     sendTransaction(
    //         cmd,
    //         previewContent,
    //         payload.transactionMessage,
    //         callback ?? (() => alert(payload.successMessage))
    //     );
    // }
}

//TODO: revise and remove if not needed
//********************************** OLD: WLL BE DEPRECATED SOON *******************************/
function useMintKadcarFunction() {
    const { account, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);
    const { pricePerKadcar } = useContext(KadcarGameContext);

    return (amount, callback) => {
        const priceToPay = amount * pricePerKadcar;
        const pactCode = getPactCommandForMintingNft(account.account);
        // const pactCode = getPactCommandForMintingKadcarNft(account.account, model);

        const cmd = {
            pactCode,
            caps: [
                Pact.lang.mkCap(`Pay to manufacture`, "Pay to manufacture", `coin.TRANSFER`, [
                    account.account,
                    ADMIN_ADDRESS,
                    priceToPay,
                ]),
                Pact.lang.mkCap(
                    "Verify your account",
                    "Verify your account",
                    `free.${KADCAR_NFT_COLLECTION}.ACCOUNT_GUARD`,
                    [account.account]
                ),
                Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
            ],
            sender: account.account,
            gasLimit: 3000 * amount,
            gasPrice,
            chainId,
            ttl: 600,
            envData: {
                "user-ks": account.guard,
                account: account.account,
            },
            signingPubKey: account.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>
                You will manufacture 1 for {priceToPay} KDA
            </p>
        );

        sendTransaction(
            cmd,
            previewContent,
            `Manufacturing 1`,
            callback ?? (() => alert("Manufactured!"))
        );
    }
}

function useTransferKadcars() {
    const { account, chainId, netId, gasPrice, sendTransaction, signTransaction } = useContext(PactContext);
    const { pricePerKadcar } = useContext(KadcarGameContext);

    return (nftId, receiver, callback) => {
        const priceToPay = 1 * pricePerKadcar;
        const pactCode = getPactCommandForTransferNft(nftId, account.account, receiver);
        const cmd = {
            pactCode,
            caps: [
                Pact.lang.mkCap(`Pay to manufacture`, "Pay to manufacture", `coin.TRANSFER`, [
                    account.account,
                    ADMIN_ADDRESS,
                    priceToPay,
                ]),
                Pact.lang.mkCap(
                    "Verify your account",
                    "Verify your account",
                    `free.${KADCAR_NFT_COLLECTION}.ACCOUNT_GUARD`,
                    [account.account]
                ),
                Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
            ],
            sender: account.account,
            gasLimit: 3000 * 1,
            gasPrice,
            chainId,
            ttl: 600,
            envData: {
                "user-ks": account.guard,
                account: account.account,
            },
            signingPubKey: account.guard.keys[0],
            networkId: netId,
        };
        const previewContent = (
            <p>
                You will manufacture 1 for {priceToPay} KDA
            </p>
        );
        sendTransaction(
            cmd,
            previewContent,
            `Manufacturing 1`,
            callback ?? (() => alert("Manufactured!"))
        );
        // signTransaction(currTransactionState.cmdToConfirm);
        signTransaction(cmd);
    }
}

export {
    useGetMyKadcars,
    useGetAllKadcars,
    useTransferKadcars,
    useMintNftContract,
    pactContractSender,
    useGetKadcarByNftId,
    useMintKadcarFunction,
    useUpgradeNftContract,
    useCreateTokenContract,
    useGetMyKadcarsFunction,
    useGetAllKadcarsFunction,
    useGetTokenManifestContract,
    useMintBulkWithKadcarsFactory,
    useDefpactFunction,
    useGetListOfAvailableTokens,
    useGetAccountRecordsInfo,
    useGetListOfMintedTokens,
    useGetStickerByOwner,
    useApplyStickerUpgradeContract,
    useTransferKadcarsWithUniversalLedger
}