import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { PactContext } from '../../pact/PactContextProvider';
import { IPFS_URL_PREFIX, LOCAL_ACCOUNT_KEY, USER_WEBP_URLS, USER_GLB_URLS, DEFAULT_POLLING_TIMER } from '../../utils/Constants';
import { extractObjectFromGLB, formatAssetIpfsUrl, getIpfsDirectoryContents, tryLoadLocal, trySaveLocal } from '../../utils/utils';
import { useGetListOfMintedTokens, useGetAccountRecordsInfo } from '../../pact/PactContractHooks';
import { useGLTF } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { dumpObject } from '../../graphics/SceneUtils';
import { useInterval } from '@chakra-ui/react';

const KadcarGameContext = createContext();

const KadcarGameContextProvider = ({ children }) => {
    const [glbUrls, setGlbUrls] = useState(null);
    const [webpUrls, setWebpUrls] = useState(null);
    const [myKadcars, setMyKadcars] = useState(null);
    const [allKadcars, setAllKadcars] = useState(null);
    const [myKadcarIds, setMyKadcarIds] = useState(null);
    const [allKadcarIds, setAllKadcarIds] = useState(null);
    const [numWhitelists, setNumWhitelists] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(null);
    const { account, accountRecordInfo, updateAccountBalanceData } = useContext(PactContext);
    const pricePerKadcar = 25; //TODO: change this!
    
    const getMintedTokenSupply = useGetListOfMintedTokens();
    const getAccountRecordsInfo = useGetAccountRecordsInfo();
    
    const [loadingNfts, setLoadingNfts] = useState(false);
    const [fetchNfts, setFetchNfts] = useState(false);
    const [mintModalOpen, setMintModalOpen] = useState(false);
    const [currentMintedTokens, setCurrentMintedTokens] = useState();
    // const [currentMintPrice, setCurrentMintPrice] = useState();
    // const [currentPricePerKadcar, setCurrentPricePerKadcar] = useState();
    // const [currentBasePrice, setCurrentBasePrice] = useState();

    useInterval(() => { account && updateAccountBalanceData(account.account) }, DEFAULT_POLLING_TIMER);

    // Memoize the parameters of the game context to avoid having to retrieve the data on every render
    // but instead, only rerun them whenever the new data is different from the cached ones
    const contextParameters = useMemo(() => {
        var parameters = {
            numWhitelists,
            currentScreen,
            myKadcars,
            webpUrls,
            glbUrls,
            myKadcarIds,
            allKadcars,
            allKadcarIds,
            pricePerKadcar,
            currentMintedTokens,
            mintModalOpen,
            loadingNfts,
            // currentPricePerKadcar,
            // currentMintPrice,
            setLoadingNfts,
            setMintModalOpen,
            setNumWhitelists,
            setMyKadcars,
            setMyKadcarIds,
            setAllKadcars,
            setAllKadcarIds,
            setCurrentMintedTokens,
            setCurrentScreen,
            calculateBaseKadcarPrice,
            getMintedTokenSupply,
            getTokenSupply,
            calculateTotalMintPrice,
            // setCurrentMintPrice,
            // setCurrentPricePerKadcar
        }
        return parameters;
    }, [
        numWhitelists,
        currentScreen,
        myKadcars,
        webpUrls,
        glbUrls,
        myKadcarIds,
        allKadcars,
        allKadcarIds,
        pricePerKadcar,
        currentMintedTokens,
        mintModalOpen,
        loadingNfts,
        // currentPricePerKadcar,
        // currentMintPrice,
        setMintModalOpen,
        setNumWhitelists,
        setLoadingNfts,
        setMyKadcars,
        setMyKadcarIds,
        setAllKadcars,
        setAllKadcarIds,
        setCurrentMintedTokens,
        setCurrentScreen,
        getMintedTokenSupply,
        getTokenSupply,
        calculateTotalMintPrice,
        // setCurrentMintPrice,
        // setCurrentPricePerKadcar
    ]);
    
    //If account changed, reset the screen contents and cached kadcars
    useEffect(() => {
        const localAccount = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        updateCurrentMinted();

        if (localAccount === null) {
            trySaveLocal(LOCAL_ACCOUNT_KEY, "");
            clearState();
        } else {
            if (account) {
                setLoadingNfts(true);
                if (account.account !== localAccount.account) {
                    setMyKadcars(null);
                    setCurrentScreen(null);
                }
            } else {
                setMyKadcars(null);
                setCurrentScreen(null);
            }
        }
    }, [account]);

    // useEffect(() => {
    //     console.log(myKadcars)
    //     if (myKadcars !== null && myKadcars?.length !== tryLoadLocal(USER_WEBP_URLS)?.length) {
    //         formatKadcarNftIpfsUrls();
    //     }
    // }, [myKadcars]);

    function clearState() {
        setMyKadcars(null);
        setMyKadcarIds(null);
        setAllKadcars(null);
        setAllKadcarIds(null);
        setCurrentScreen(null);
        setGlbUrls(null);
        setWebpUrls(null);
    }

    async function updateCurrentMinted() {
        let mintedTokens = await getTokenSupply();
        setCurrentMintedTokens(mintedTokens);
    }

    async function formatKadcarNftIpfsUrls() {
        var webpurls = [];
        var glburls = [];

        for (var i = 0; i < myKadcars.length; i++) {
            // var glbCid = myKadcars[i].data[2]['datum']['art-asset']['data'].split('//')[1];
            var webpCid = myKadcars[i].uri['data'].split('//')[1];

            // var formattedGlbUrl = await formatAssetIpfsUrl(glbCid);
            var formattedWebpUrl = await formatAssetIpfsUrl(webpCid);

            // glburls.push(formattedGlbUrl);
            webpurls.push(formattedWebpUrl);
        }
        
        // console.log(glburls)
        
        // setGlbUrls(glburls);
        setWebpUrls(webpurls);

        // trySaveLocal(USER_GLB_URLS, glburls);
        trySaveLocal(USER_WEBP_URLS, webpurls);
    }

    async function getTokenSupply(amountToMint=null, setCurrentPricePerKadcar=null, setCurrentBasePrice=null, setCurrentMintPrice=null) {
        const response = await getMintedTokenSupply();

        const tokenList = response?.result?.data;
        const requestKey = response?.reqKey;

        var identical = (tokenList?.length == currentMintedTokens?.length) && tokenList?.every(function(element, index) {
            return element === currentMintedTokens[index]; 
        });

        if (!identical) {
            if (amountToMint > 0) {
                let totalMintPrice = calculateTotalMintPrice(amountToMint, tokenList);
                let pricePerKadcar = calculateTotalMintPrice(1, tokenList);
                
                trySaveLocal("currentMintPrice", totalMintPrice);
                trySaveLocal("currentPricePerKadcar", pricePerKadcar);
    
                amountToMint && setCurrentMintPrice && setCurrentMintPrice(totalMintPrice);
                setCurrentPricePerKadcar && setCurrentPricePerKadcar(pricePerKadcar);
            }
            setCurrentMintedTokens(tokenList);
        }

        return tokenList;
    }

    function calculateTotalMintPrice(amountToMint, currentMintedTokenList) {
        let totalMintedTokensCounter = currentMintedTokenList.length;
        let accountRecordsInfo = JSON.parse(JSON.stringify(accountRecordInfo));

        let totalPrice = 0.0;

        for (var i = 0; i < amountToMint; i++) {
            totalMintedTokensCounter++;
            let basePrice = calculateBaseKadcarPrice(totalMintedTokensCounter);

            let priceModifier = calculatePriceModifier(accountRecordsInfo);
            accountRecordsInfo = priceModifier.accountRecordsInfo;

            totalPrice += (basePrice * priceModifier.modifier);
        }

        return parseFloat(totalPrice.toFixed(4));
    }

    //Function to compute price of a kadcar given an amount
    function calculateBaseKadcarPrice(numberOfTokens) {
        // return Math.round(pricePerKadcar * amount * 100) / 100;

        if (numberOfTokens >= 0 && numberOfTokens <= 1000) {
            return 25.0;    
        } else if (numberOfTokens <= 1500) {
            return 29.0;    
        } else if (numberOfTokens <= 2000) {
            return 33.0;    
        } else if (numberOfTokens <= 2500) {
            return 36.0;    
        } else if (numberOfTokens <= 3000) {
            return 39.0;    
        } else if (numberOfTokens <= 3500) {
            return 44.0;    
        } else if (numberOfTokens <= 4000) {
            return 49.0;    
        } else if (numberOfTokens <= 4500) {
            return 55.0;    
        } else if (numberOfTokens <= 5000) {
            return 59.0;    
        } else if (numberOfTokens <= 5337) {
            return 65.0;    
        }
        return 65.0;
        // if (numberOfTokens >= 0 && numberOfTokens <= 6000) {
        //     return 1.0;    
        // } else if (numberOfTokens <= 62) {
        //     return 2.0;    
        // } else if (numberOfTokens <= 63) {
        //     return 3.0;    
        // } else if (numberOfTokens <= 64) {
        //     return 4.0;    
        // } else if (numberOfTokens <= 65) {
        //     return 5.0;    
        // } else if (numberOfTokens <= 66) {
        //     return 6.0;    
        // } else if (numberOfTokens <= 67) {
        //     return 7.0;    
        // } else if (numberOfTokens <= 68) {
        //     return 8.0;    
        // } else if (numberOfTokens <= 69) {
        //     return 9.0;    
        // } else if (numberOfTokens <= 70) {
        //     return 10.0;    
        // } else if (numberOfTokens <= 71) {
        //     return 11.0;    
        // }
        // return 65.0;
    }

    function calculatePriceModifier(accountRecordsInfo) {
        if (accountRecordsInfo['free-mints-remaining']['int'] > 0) {
            accountRecordsInfo['free-mints-remaining']['int'] = accountRecordsInfo['free-mints-remaining']['int'] - 1;
            accountRecordsInfo['minted-total']['int'] = accountRecordsInfo['minted-total']['int'] + 1;
            
            return {
                modifier: 0.0, //TODO: final value
                accountRecordsInfo: accountRecordsInfo
            };
        } else if (accountRecordsInfo['minted-total']['int'] === accountRecordsInfo['free-mints-granted']['int'] && accountRecordsInfo['is-champion']) { //TODO: finalize champion
            accountRecordsInfo['minted-total']['int'] = accountRecordsInfo['minted-total']['int'] + 1;
            
            return {
                modifier: 0.7, //TODO: final value
                accountRecordsInfo: accountRecordsInfo
            };
        } else if (accountRecordsInfo['whitelists-remaining']['int'] > 0) {
            accountRecordsInfo['whitelists-remaining']['int'] = accountRecordsInfo['whitelists-remaining']['int'] - 1;
            accountRecordsInfo['minted-total']['int'] = accountRecordsInfo['minted-total']['int'] + 1;
            
            return {
                modifier: 0.85, //TODO: final value
                accountRecordsInfo: accountRecordsInfo
            };
        } else {
            accountRecordsInfo['minted-total']['int'] = accountRecordsInfo['minted-total']['int'] + 1;

            return {
                modifier: 1.0,
                accountRecordsInfo: accountRecordsInfo
            }
        }
    }

    return (
        <KadcarGameContext.Provider value={contextParameters}>
            { children }
        </KadcarGameContext.Provider>
    )
}

export {
    KadcarGameContext,
    KadcarGameContextProvider
}