import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import ReactDOM from 'react-dom';

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import './App.css';
import "./assets/scss/style.scss";
import "./global.css";
import { CSSReset, ChakraProvider } from "@chakra-ui/react";

//declare firebase config object
// const firebaseConfig = {
//   apiKey: "AIzaSyD4IYkW_UOb8DD2M_SDCMDUa8C16AfmWT4",
//   authDomain: "kc-whitelist.firebaseapp.com",
//   databaseURL: "https://kc-whitelist-default-rtdb.firebaseio.com/",
//   projectId: "kc-whitelist",
//   storageBucket: "kc-whitelist.appspot.com",
//   messagingSenderId: "846224355881",
//   appId: "1:846224355881:web:b88b33dadb85ba3633a238",
//   measurementId: "G-1DNHTLES8T"
// };

//Initialize firebase app
// initializeApp(firebaseConfig);

// const history = createBrowserHistory();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <ChakraProvider>
            <CSSReset />
            <App />
        </ChakraProvider>
    </BrowserRouter>
    // document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
