import React, { useContext, useEffect, useState } from "react";
import Modal from "../components/elements/Modal";
import Select from "react-select";
import { throttle } from "throttle-debounce";
import { KadcarGameContext } from "../components/kadcarcomponents/KadcarGameContextProvider";
import { useTransferKadcars, useTransferKadcarsWithUniversalLedger } from "../pact/PactContractHooks";
import { checkIfNullOrUndefined } from "../utils/utils";
import Button from "../components/elements/Button";
import { fetchAccountDetails } from "../pact/PactUtils";
import { DEFAULT_CHAIN_ID, DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE } from "../utils/Constants";
import { toast } from "react-toastify";

const defaultReceiver = {
    account:
        "k:3e84c7a7a21e69e666a82f8a38f55fe79049fa6b675860681f11f514d92ae6f5",
    chainId: "1",
    gasPrice: DEFAULT_GAS_PRICE,
    gasLimit: DEFAULT_GAS_LIMIT,
};

function getKadcarOptionsForDropdown(kadcarList) {
    const kadcarOptions = kadcarList.map((kadcarNft) => {
        var newOption = {
            value: kadcarNft["nft-id"],
            label: kadcarNft["nft-id"],
        };
        return newOption;
    });
    return kadcarOptions;
}

const TransferNftModal = ({ show, setShow, nftId }) => {
    const transferKadcarsFunction = useTransferKadcarsWithUniversalLedger();
    const [selectedNfts, setSelectedNfts] = useState(null);
    const [receiverAccount, setReceiverAccount] = useState();
    const [transferReady, setTransferReady] = useState(false);
    const { myKadcars, setMyKadcars } = useContext(KadcarGameContext);
    const [kadcarOptions, setKadcarOptions] = useState(null);

    useEffect(() => {
        function getOptions() {
            myKadcars &&
                setKadcarOptions(getKadcarOptionsForDropdown(myKadcars));
        }
        getOptions();
    }, [myKadcars]);

    function handleTransferModalClose() {
        setShow(false);
    }

    function handleReceiverAccountChange(event) {
        setTransferReady(true);
        setReceiverAccount(event.target.value);
    }

    function removeTransferredKadcarFromList() {
        const updatedList = myKadcars.filter((kadcar) => {
            let splitId = nftId.split(":")
            return kadcar.data[1]['datum']["vehicle-information"]["vin"] !== splitId[splitId.length-1];
        });
        setMyKadcars(updatedList);
        setKadcarOptions(updatedList);
    }

    async function initiateKadcarTransfer() {
        const txData = {
            account: receiverAccount,
            chainId: DEFAULT_CHAIN_ID,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE
        }
        const accountDetails = await fetchAccountDetails(txData);
        if (checkIfNullOrUndefined(accountDetails)) {
            toast.error("Sorry the receiver acount details are invalid, wallet not found");
            return false;
        }

        handleTransferModalClose();
        transferKadcarsFunction(nftId, receiverAccount, removeTransferredKadcarFromList)
    }

    return (
        <Modal show={show} handleClose={handleTransferModalClose}>
            <div style={modalStyles}>
                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "lightgray",
                        textAlign: "center",
                        marginBottom: "10px",
                    }}
                >
                    Transfer a Kadcar(s)
                </div>
                <div style={{ marginTop: "8px" }}>
                    <div style={subColLabelStyles}>Receiver's address:</div>
                    <div style={subColInputStyles}>
                        <input
                            style={{ padding: "10px  14px", width: "100%" }}
                            type="text"
                            onChange={handleReceiverAccountChange}
                            placeholder={"e.g. k:1234..."}
                        />
                    </div>
                </div>
                {/* <div style={{}}>
                    <div style={subColLabelStyles}>
                        Receiver's wallet chain ID:
                    </div>
                    <div style={subColInputStyles}>
                        <input
                            style={{ padding: "10px  14px", width: "100%" }}
                            type="number"
                            value={receiverAccount.chainId}
                            onChange={handleReceiverChainIdChange}
                            placeholder={"e.g. 0,1.."}
                        />
                    </div>
                </div> */}
                {/* <div style={{}}>
                    <div style={subColLabelStyles}>
                        Select Kadcar to transfer:
                    </div>
                    <div style={subColInputStyles}>
                        {myKadcars && (
                            <Select
                                options={kadcarOptions}
                                onChange={onSelectNftOptions}
                                style={{ zIndex: "10000" }}
                            />
                        )}
                    </div>
                </div> */}
                <div style={{}}>
                    <Button
                        onClick={initiateKadcarTransfer}
                        color={"primary"}
                        disabled={!transferReady}
                        className="buttons"
                        style={{
                            borderRadius: "8px",
                            width: "100%",
                            marginTop: "10px",
                            zIndex: "0",
                        }}
                    >
                        Transfer
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    width: "100%",
    // height: "35vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // backgroundColor: 'green'
};

// const headerStyles = {
//     width: "100%",
//     height: "20%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     // backgroundColor:'blue'
// };

// const rowStyles = {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     width: "100%",
// };

const subColLabelStyles = {
    fontSize: "16px",
    marginBottom: "4px",
    // width: "60%",
};

const subColInputStyles = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // marginLeft: "5px",
    marginBottom: "10px",
    zIndex: "1000",
};

export { TransferNftModal };
