import React, { useContext } from "react";
import { DEFAULT_POLLING_TIMER } from "../utils/Constants";
import { useInterval } from "../utils/utils";
import { KadcarGameContext } from "./kadcarcomponents/KadcarGameContextProvider";

const ProgressBarSection = () => {
    const { currentMintedTokens, getTokenSupply } = useContext(KadcarGameContext);
    useInterval(getTokenSupply, DEFAULT_POLLING_TIMER);

    const progressBarStatus = 60;
    let numberMinted = 60;
    let maxSupply = 5337;

    return (
        <div style={{ marginBottom: "30px" }}>
            <div style={{ textAlign: "center" }}>
                {currentMintedTokens ? currentMintedTokens.length : 0}/{maxSupply}{" "}
            </div>
            <div
                className="progress-bar"
                style={{ width: `${100}%`, backgroundColor: "#fff" }}
            >
                <span className="bar">
                    <span
                        className="progress"
                        style={{ width: `${currentMintedTokens ? (currentMintedTokens.length / maxSupply) : 0}%` }}
                    ></span>
                </span>
            </div>
        </div>
    );
};

export default ProgressBarSection;
