import { Box, Flex, Icon, Link, Stack } from "@chakra-ui/react";
import React from "react";
import PagerContainer from "./PagerContainer";
import { FaDiscord } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
    return (
        <Box bgColor="#000">
            <PagerContainer>
                {/* <Flex
                    justify="flex-end"
                    gap={["2.25rem", null, "5rem"]}
                    flexDir={["column", null, "row"]}
                >
                    <Stack>
                        <Link href="#" color="white">
                            Team
                        </Link>
                        <Link href="#" color="white">
                            about the game
                        </Link>
                        <Link href="#" color="white">
                            terms and conditions
                        </Link>
                    </Stack>

                    <Flex align="flex-end" justify={"space-between"}>
                        <Stack>
                            <Link href="#" color="white">
                                Mint
                            </Link>
                            <Link href="#" color="white">
                                Marketplace
                            </Link>
                            <Link href="#" color="white">
                                Kadena
                            </Link>
                        </Stack>
                        <Flex
                            mt="3.37rem"
                            gap="1.75rem"
                            justify="flex-end"
                            display={["flex", null, "none"]}
                        >
                            <Link href="#">
                                <Icon
                                    as={BsTwitterX}
                                    boxSize={"2rem"}
                                    color="white"
                                />
                            </Link>
                            <Link href="#">
                                <Icon
                                    as={FaDiscord}
                                    boxSize={"2rem"}
                                    color="white"
                                />
                            </Link>
                        </Flex>
                    </Flex>
                </Flex> */}
                <Flex
                    gap="1.75rem"
                    justify="center"
                    align="center"
                    // display={["none", null, "flex"]}
                    py="5rem"
                >
                    <Link
                        target="_blank"
                        href="https://twitter.com/kadcarsnft?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    >
                        <Icon as={BsTwitterX} boxSize={"2rem"} color="white" />
                    </Link>
                    <Link href="http://discord.gg/74znq8jM8a" target="_blank">
                        <Icon as={FaDiscord} boxSize={"2rem"} color="white" />
                    </Link>
                </Flex>
            </PagerContainer>
        </Box>
    );
};

export default Footer;
