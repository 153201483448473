import React from "react";

const KadcarAccountStatement = ({ account, accountBalance }) => {
    return (
        <div
            style={{
                maxWidth: "500px",
                margin: "0px auto 10px auto",
                textAlign: "center",
            }}
        >
            <div style={{ display: "flex", gap: "32px" }}>
                <div style={{ display: "flex" }}>
                    <div>
                        <span style={{ fontWeight: "bold", color: "#f854e4", fontSize: '17px' }}>
                            Active Account:{" "}
                        </span>
                    </div>

                    <div>
                        <span style={{ fontSize: '17px' }}>
                            &nbsp;&nbsp; {account.account.slice(0, 10)}...
                            {account.account.slice(
                                account.account.length - 3,
                                account.account.length
                            )}
                        </span>
                    </div>
                </div>
                <div>
                    <span style={{ fontWeight: "bold", color: "#f854e4", fontSize: '17px' }}>
                        Balance:{" "}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ fontSize: '17px' }}>
                        {accountBalance ? accountBalance.balance : null}
                    </span>
                    &nbsp;&nbsp;KDA
                </div>
            </div>
        </div>
    );
};

export default KadcarAccountStatement;
