import { Box } from "@chakra-ui/react";
import React from "react";
import IsokoNav from "./IsokoNav";
import Hero from "./Hero";
import R2r from "./R2r";
import HowToPlay from "./HowToPlay";
import Footer from "./Footer";
import Team from "./Team";
import Upgrade from "./Upgrade";
import H_1 from "../assets/iso/h_1.png";
const Pager = () => {
    return (
        <Box className="pager">
            <IsokoNav />
            <Box
                minH="100vh"
                bg={`linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${H_1}), lightgray 50% / cover no-repeat`}
                bgPos={"center"}
                bgSize={"cover"}
                bgRepeat={"no-repeat"}
            >
                <Hero />
            </Box>
            <Box>
                <R2r />
            </Box>
            <Box>
                <Upgrade />
            </Box>
            <Box>
                <HowToPlay />
            </Box>
            <Box>
                <Team />
            </Box>
            <Box>
                <Footer />
            </Box>
        </Box>
    );
};

export default Pager;
