const WHITELIST_WINNERS_SPREADSHEET_ID = "1Q61_kSKl1TNVvOh_thr5UAoBvWxwy-mWXvNZL3CBjHk";
const WHITELIST_WINNERS_SHEET_ID = "0";
const SERVICE_ACCOUNT_PRIVATE_KEY_ID = "e24bc2f878a2ce67ff4e2d526c564eb791ee36b9";
const SERVICE_ACCOUNT_PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDhK2Qs6Ew1aL8O\neXZKuhuPCOv+x61npxRt5UKIujzqZ3dmS91g54FnOisNx3yPbwdbQMeRfG4QTFIp\nRWGkGAhtbc9P+gj2K1ZZGNB5Pl2m5KGgjxn+JkI6gSKYidjetoYq6im3y5cgIiZo\nc9bHIdhs+p1U7mfKHeBQnHJjIlCSQQ7qMjWpE1EgrWF3DhkhLJvyvx2k8y4ew6W8\nA7l40Uns9Mt0OXlfywET8vEQ0N8Gahk20nt7OE0iGraewxXaviH29uxvKjgpCuYW\nOHkZWtMfmFcLdjox/8TtV+JnrhWJrMGcYfBkr41clZr359j+vk5Fo2pcCbXYoZD+\nOXyGAoyFAgMBAAECggEAD1LQom28ofXa6rVBY5x+u6EzzkbwehlYvpHXkU57Tshz\ny7Zl/m5C1C+VNn7Y7znzGjayXihFpT5MaRqbpnssN6Y9cYfmjDouyilyJo4j+XOd\nvDuA3VblMu4O2Kqo458wSkNdS3DDfCBW1MztVOfsvp3fjyQaVBrsZRAAiNaQ/SLn\nnzK6ZCFGib3fhAtLAi51NApTENW29wSBRJ1CBjbb0cEe38k0gT6tYb8lTE9DcltE\nXtsMFpDIADG79+2T9mNG50kibrsJoO1JXLO9j7sI3pwmsNqY8a8/7AY0mR5ToAp7\np/PTWPJ/tNxPHCEAWvs/BJnXm1sAcr5FFJnLXMSvgQKBgQDxOEEQ1QyZtIVW4VGt\nJUlPR8ZAlfLU40p3KHkWBQzOjlohS7imTvvBOfR02/hvUcGNnz4rjzTxHRRPvC4e\n/MUuRTeuVqaL5UrmGORK3FrQa/oabGLJH24pIO62xXjhVME0cyVyN5ZdvWPTWMLi\nllKVtMyH1dzfv9cePj94kT2hOQKBgQDu91/ynLZvJ/FTQcWo+NGn52IR6f3VoayY\nWyU8KgQxZ4Ekte4YfiT3u+L9CpFGg9QDFwntGld5WhsOZSIsIqpbGF/chmgZyL+j\nk+F7aby0UJp9FI4sLWuiCgzWQzd1C+5S7vLVJhrBc8zCUNh6qAq3bTPcBtQ5Lq0R\n7NAAXXZhrQKBgFODqHNNb9lfeLLFu+N4f1MlhGwj/jjaoJN0vcdz5CU3f9j7QGjD\n+8HAcPRsnCzeX2bK9VlbcXbxHHEms8kitpn4ePixI2RX9ICJqbCJbh+u4p53XBRJ\n/3fZ4QwCfoF1OJcSLqrGuBaoVYF8v6x3YwxV1mJJFvmYj7/2yT7S+kVxAoGAeR1n\nw88pp6zW4xuy0rFJkY/0ZJ/zxxLqJ04K0TN6bI/JJJIQ2tkUY3sW0KlKeDLYgbhr\nj4CKVTcV/3XCnBv1u7Xe4CY/HNJiTsXkwmgU/E7d/gctB7b9lmMBnpbyxKAHaoEx\nElb9uI64eMQA9Cm3RMBUSHAtn83Iag1cXtL/7QECgYBiSbcvEGCcrBPE1ERbHvcj\n0CAvx12nkQgGfrqzxRmoVAwc7Y8fjRawLCd3zAttyL2cY2GaiNodqs4Nq2nMLOCy\ndEV1gYJVZ3fEgajNoKFLYWzYUJzNlYU21M0AJF5qvygcPGhWw0ZKfXFlYu3aMYnk\ng+ATELQkogyAiT6FRIMHsA==\n-----END PRIVATE KEY-----\n";
const SERVICE_ACCOUNT_CLIENT_EMAIL = "kadcarsnft@kadcarsnft.iam.gserviceaccount.com";
const SERVICE_ACCOUNT_CLIENT_ID = "112615336733804198992";

export {
    WHITELIST_WINNERS_SHEET_ID,
    WHITELIST_WINNERS_SPREADSHEET_ID,
    SERVICE_ACCOUNT_CLIENT_ID,
    SERVICE_ACCOUNT_CLIENT_EMAIL,
    SERVICE_ACCOUNT_PRIVATE_KEY,
    SERVICE_ACCOUNT_PRIVATE_KEY_ID,
}