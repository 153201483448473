import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { FeatureItem } from "../elements/FeatureItem";
import {
    IoRocketSharp,
    IoCarSportSharp,
    IoGameController,
    IoDiamondSharp,
} from "react-icons/io5";

const propTypes = {
    ...SectionTilesProps.types,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
};
const Roadmap = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        "features-tiles section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "features-tiles-inner section-inner pt-0",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
        "tiles-wrap center-content",
        pushLeft && "push-left"
    );

    const sectionHeader = {
        title: "Kadcars' 2022 Roadmap",
        paragraph: "",
    };

    return (
        <section
            {...props}
            className={outerClasses}
            style={{ display: "none" }}
        >
            <div className="container" style={{ marginTop: "80px" }}>
                <div className={innerClasses}>
                    <SectionHeader
                        data={sectionHeader}
                        className="center-content"
                    />
                    <div className={tilesClasses}>
                        <FeatureItem
                            labelName={"Q3, 2022"}
                            list={[
                                "K:2 model completion",
                                "New K:2 pickup variation",
                                "Team Expansion : Game Development",
                                "Game Phyics setup",
                                "Game NFT Import",
                            ]}
                            completionList={[true, true, true, true, true]}
                        >
                            <IoRocketSharp color="white" size={"4vh"} />
                        </FeatureItem>
                        <FeatureItem
                            labelName={"Q4, 2022"}
                            list={[
                                "K:2 model optimization",
                                "NFT contracts mainnet",
                                "K:1 car model draft",
                                "Team expansion : Marketing",
                                "Game tracks + UX",
                            ]}
                            completionList={[true, true, true, true, true]}
                        >
                            <IoCarSportSharp color="white" size={"4vh"} />
                        </FeatureItem>
                        <FeatureItem
                            labelName={"Q1, 2023"}
                            list={[
                                "K:2 Mint",
                                "Marketing : Gameplay content release",
                                "Game contracts to mainnet",
                                "Game closed beta testing",
                                "Manifest Upgradability",
                            ]}
                            completionList={[false, true, false, false, false]}
                        >
                            <IoGameController color="white" size={"4vh"} />
                        </FeatureItem>
                        <FeatureItem
                            labelName={"Q2, 2023"}
                            list={[
                                "Token Listing",
                                "Game full beta/public release",
                                "Upgrade NFTs",
                            ]}
                            completionList={[false, true, false]}
                        >
                            <IoDiamondSharp color="white" size={"4vh"} />
                        </FeatureItem>
                        {/* <FeatureItem labelName={"November"} list={["Combine NFTs with kadcars", "Other exciting things coming!"]}>
              <FaFlagCheckered color='white' size={'4vh'}/>
            </FeatureItem> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
