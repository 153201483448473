import {
    NETWORK_ID,
    KDA_CHECK_STATUS,
    KDA_CONNECT,
    KDA_DISCONNECT,
    KDA_GET_SELECTED_ACCOUNT,
    KDA_REQUEST_ACCOUNT,
    KDA_REQUEST_SIGN,
    KDA_GET_NETWORK,
    KDA_GET_CHAIN,
    KDA_QUICK_SIGN
} from "../utils/Constants";

//Attempt to connect application to Kadena eckoWALLET extension
async function connectKadena(netId) {

    //Initiate KDA connect
    const response = await window.kadena.request({
        method: KDA_CONNECT,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message)
            return;
        });

    return response;
}

//Check the user's Kadena extenstion connection status
async function getKadenaConnectStatus(netId) {
    const response = await window.kadena.request({
        method: KDA_CHECK_STATUS,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });
    return response;
}

async function requestKadenaAccount(netId) {
    const response = await window.kadena.request({
        method: KDA_REQUEST_ACCOUNT,
        networkId: NETWORK_ID,
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });
    return response;
}

async function requestSign(netId, dataToSign) {
    const response = await window.kadena.request({
        method: KDA_REQUEST_SIGN,
        networkId: netId,
        data: dataToSign
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function getSelectedAccount(netId) {
    const response = await window.kadena.request({
        method: KDA_GET_SELECTED_ACCOUNT,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

//Disconnect the user's eckoWALLET account from this application
async function disconnectKadena(netId) {
    const response = await window.kadena.request({
        method: KDA_DISCONNECT,
        networkId: NETWORK_ID
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });
    return response;
}

async function getNetwork() {
    const response = await window.kadena.request({
        method: KDA_GET_NETWORK
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function getChain() {
    const response = await window.kadena.request({
        method: KDA_GET_CHAIN
    })
        .catch((e) => {
            console.error(e.message);
            return;
        });

    return response;
}

async function quickSign(cmd, accountPublicKey) {
    const response = await window.kadena.request({
        method: KDA_QUICK_SIGN,
        data: {
            networkId: 'testnet04',
            commandSigDatas: [
                {
                    sigs: [accountPublicKey],
                    cmd: JSON.stringify(cmd),
                }
            ],
        },
    })
    .catch((e) => {
        console.error(e.message);
        return;
    })

    return response;
}


export {
    getChain,
    getNetwork,
    requestSign,
    connectKadena,
    disconnectKadena,
    getSelectedAccount,
    requestKadenaAccount,
    quickSign,
    getKadenaConnectStatus,
}