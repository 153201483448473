import React, { useContext, useState } from "react";
import { FaDiscord, FaMediumM, FaTwitter } from "react-icons/fa";
import { PactContext } from "../../../pact/PactContextProvider";
import KadcarAccountStatement from "../../kadcarcomponents/KadcarAccountStatement";
import { KadcarGameContext } from "../../kadcarcomponents/KadcarGameContextProvider";

// import { DISCORD_URL, MEDIUM_URL, TWITTER_URL } from "../../../utils/Constants";
// import { AiFillMediumCircle, AiFillTwitterCircle } from "react-icons/ai";
// import { BsDiscord } from "react-icons/bs";

// const SocialLink = ({ children, name, size, url }) => {
//     return (
//         <li>
//             <a href={url}>
//                 <BsDiscord />
//             </a>
//         </li>
//     );
// };

const FooterSocial = ({ className, ...props }) => {
    // const classes = classNames("footer-social", className);
    const { account, accountBalance, isXwallet } = useContext(PactContext);
    const { setCurrentScreen } = useContext(KadcarGameContext);
    const [isActive, setIsactive] = useState(false);

    return (
        <div {...props} className={"social_Icons"}>
            {/* <div className={"social_Icons"}>
                <div style={{ display: "flex", gap: "32px" }}>
                    <a className="iconBox" href="https://discord.gg/PPX9CupYZT">
                        <FaDiscord className="icon" />
                    </a>
                </div>

                <div style={{ display: "flex", gap: "32px" }}>
                    <a
                        className="iconBox"
                        href="https://twitter.com/kadcarsnft"
                    >
                        <FaTwitter className="icon" />
                    </a>
                </div>

                <div style={{ display: "flex", gap: "32px" }}>
                    <a
                        className="iconBox"
                        href="https://medium.com/@kadcarsnft"
                    >
                        <FaMediumM className="icon" />
                    </a>
                </div>
            </div> */}
            <div>
                {account ? <KadcarAccountStatement account={account} accountBalance={accountBalance} /> : null}
            </div>
        </div>
    );
};

export default FooterSocial;

const socials = [
    {
        href: "https://discord.gg/PPX9CupYZT",
        icon: <FaDiscord className="icon" />,
    },
    {
        href: "https://twitter.com/kadcarsnft",
        icon: <FaTwitter className="icon" />,
    },
    {
        href: "https://medium.com/@kadcarsnft",
        icon: <FaMediumM className="icon" />,
    },
];
