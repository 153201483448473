import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "../components/elements/Modal";
import { PactContext } from "../pact/PactContextProvider";
import { useMintBulkWithKadcarsFactory, useGetTokenManifestContract, useGetAccountRecordsInfo, useMintNftContract } from "../pact/PactContractHooks";
import Select from 'react-select';
import { DEFAULT_POLLING_TIMER, KADCAR_NFT_OPTIONS } from "../utils/Constants";
import Button from "../components/elements/Button";
import { checkIfItemExistsInDropdownList, checkIfNullOrUndefined, tryLoadLocal, trySaveLocal } from "../utils/utils";
import { KadcarGameContext } from "../components/kadcarcomponents/KadcarGameContextProvider";
import { toast } from "react-toastify";
import { pollForTransaction } from "../pact/PactUtils";

const MintModal = ({ show, setShow, showMintError, setShowMintError }) => {
    //const mintKadcarFunction = useMintKadcarFunction();
    // const mintKadcarFunction = useMintNftContract();
    // const getMintedNftId = useGetKadcarByNftId();

    //Custom hook calls for pact contract calls
    // const mintKadcarsFunction = useMintBulkWithKadcarsFactory();
    const mintKadcarsFunction = useMintNftContract();
    const getKadcarManifest = useGetTokenManifestContract();
    const getAccountRecordsInfo = useGetAccountRecordsInfo();

    const { account, updateAccountBalanceData, accountRecordInfo, clearTransaction, setAccountRecordInfo } = useContext(PactContext);
    const {
        myKadcars,
        myKadcarIds,
        setMyKadcars,
        numWhitelists,
        setMyKadcarIds,
        pricePerKadcar,
        getTokenSupply,
        setLoadingNfts,
        setNumWhitelists,
        setMintModalOpen,
        currentMintedTokens,
        getMintedTokenSupply,
        setCurrentMintedTokens,
        setCurrentSupplyAmount,
        calculateTotalMintPrice,
        // currentMintPrice,
        // currentPricePerKadcar,
        // setCurrentMintPrice,
        // setCurrentPricePerKadcar
    } = useContext(KadcarGameContext);
    const [modelSelected, setModelSelected] = useState(KADCAR_NFT_OPTIONS[0].value);
    const [mintedNft, setMintedNft] = useState(null);
    const [amountToMint, setAmountToMint] = useState(0);
    const [currentTotal, setCurrentTotal] = useState(0.0);
    const [retryMintDialog, setRetryDialog] = useState(false);

    const [currentMintPrice, setCurrentMintPrice] = useState();
    const [currentPricePerKadcar, setCurrentPricePerKadcar] = useState();
    const [currentBasePrice, setCurrentBasePrice] = useState();

    // useEffect(() => {
    //     console.log(mintedNft)
    //     if (mintedNft) {
    //         setMyKadcars([...myKadcars, mintedNft]);
    //         updateAccountBalanceData(account.account);
    //     }
    // }, [mintedNft]);

    useEffect(() => {
        let interval;
        
        setMintModalOpen(true);
        
        if (account && show) {
            interval = setInterval(() => {
                console.log('Polling for supply')
                // getTokenSupply(amountToMint);
                getTokenSupply(amountToMint, setCurrentPricePerKadcar, setCurrentBasePrice, setCurrentMintPrice);
            }, DEFAULT_POLLING_TIMER);
        } else {
            console.log(show);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [show, amountToMint]);

    function handleClose() {
        setShow(false);
        setMintedNft(null);
        setAmountToMint(0);
        setModelSelected(null);
        setMintModalOpen(false);
        setCurrentMintPrice(0.0);
        setCurrentPricePerKadcar(0.0);
    }

    function onSelectModelOption(option) {
        setModelSelected(option.value);
    }

    async function initiateMintKadcar() {
        handleClose();

        if (account.balance < pricePerKadcar) {
            toast.error(
                `Insufficient funds! Only ${account.balance} KDA remaining.`
            );
            return;
        }
        if (
            !checkIfItemExistsInDropdownList(modelSelected, KADCAR_NFT_OPTIONS)
        ) {
            //TODO: THROW ERROR HERE
            toast.error("Please select a Kadcar model!");
            return;
        }

        mintKadcarsFunction(amountToMint, updateWithMintedNftId);
    }

    function checkIfReadyToMint() {
        if (!checkIfNullOrUndefined(modelSelected) && amountToMint > 0) {
            return false;
        }
        return true;
    }

    function updateTotalMintPrice(mintAmt) {
        let finalAmt = mintAmt;
        if (mintAmt > 1) {
            finalAmt = 1;
        }

        let amountToMint = parseFloat(finalAmt);
        let mintTotal = calculateTotalMintPrice(amountToMint, currentMintedTokens);
        let pricePerKadcar = calculateTotalMintPrice(1, currentMintedTokens);

        trySaveLocal("amountToMint", amountToMint);
        trySaveLocal("currentMintPrice", mintTotal);
        trySaveLocal("currentPricePerKadcar", pricePerKadcar);

        setCurrentTotal(mintTotal);
        setAmountToMint(amountToMint);
        setCurrentMintPrice(mintTotal);
        setCurrentPricePerKadcar(pricePerKadcar);
    }

    async function updateWithMintedNftId(data) {
        if (data) {
            console.log(data)
            var nftIds = data.result.data;

            const [manifestResult, whitelistResult, accountBalanceResult, currentMintedUpdateResult] = await Promise.allSettled([
                retrieveNftManifests(nftIds),
                getAccountRecordsInfo(),
                updateAccountBalanceData(account.account),
                updateCurrentMinted()
            ]);

            if (manifestResult.status === 'rejected') {
            }
            if (accountBalanceResult.status === 'rejected') {
            }
            if (whitelistResult.status === 'rejected') {
            } 
            if (currentMintedUpdateResult.status === 'rejected') {
            } else {
                var accountrecordinfo = whitelistResult.value;
                setAccountRecordInfo(accountrecordinfo);
                setNumWhitelists(accountrecordinfo['whitelists-remaining']['int']);
            }
        } else {
            toast.error("Invalid data, unable to update kadcar list");
        }
    }

    function updateUserKadcarList(mintedNftManifest) {
        if (mintedNftManifest) {
            setMyKadcars([...myKadcars, mintedNftManifest]);
            console.log(myKadcars)
            updateAccountBalanceData(account.account);
        }
    }

    async function retrieveNftManifests(nftIds) {
        //TODO: download glbs here
        console.log()
        setLoadingNfts(true);
        for (const nftId of nftIds) {
            setMyKadcarIds([...myKadcarIds, nftId]);
            // var manifest = await getKadcarManifest(nftId, updateUserKadcarList);
            var manifest = await getKadcarManifest(nftId, null);
            console.log(nftId)
        }
    }

    async function updateCurrentMinted() {
        let mintedTokens = await getTokenSupply();
        setCurrentMintedTokens(mintedTokens);
    }

    async function initiateMintKadcar() {
        // handleClose();

        if (account.balance < currentMintPrice) {
            toast.error(`Insufficient funds! Only ${account.balance} KDA remaining.`);
            return;
        }
        if (!checkIfItemExistsInDropdownList(modelSelected, KADCAR_NFT_OPTIONS)) {
            //TODO: THROW ERROR HERE
            toast.error("Please select a Kadcar model!");
            return;
        }

        tryLoadLocal("retry") === false ? mintKadcarsFunction(amountToMint, currentMintPrice, updateWithMintedNftId, mintErrorHandler) :
            mintKadcarsFunction(tryLoadLocal("amountToMint"), tryLoadLocal("currentMintPrice"), updateWithMintedNftId, mintErrorHandler);

        trySaveLocal("retry", false);
    }

    async function mintErrorHandler() {
        let amountLocal = tryLoadLocal("amountToMint");
        console.log(amountLocal)
        setShow(true);
        setShowMintError(true);
        setRetryDialog(true);
        getTokenSupply(amountLocal);
        updateTotalMintPrice(amountLocal);
        toast.error("Price has changed, please try again");
    }

    function retryMint() {
        let amountLocal = tryLoadLocal("amountToMint");
        getTokenSupply(amountLocal);
        updateTotalMintPrice(amountLocal);
        trySaveLocal("retry", true);
        initiateMintKadcar();
        setShowMintError(false);
    }

    function clearMintTransaction() {
        trySaveLocal("retry", false);
        trySaveLocal("amountToMint", null);
        trySaveLocal("currentMintPrice", null);
        trySaveLocal("currentPricePerKadcar", null);
    }

    return (
        <Modal show={show} handleClose={handleClose}>
            {
                !showMintError ?
                    <div style={modalStyles}>
                        <div
                            style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "lightgray",
                                alignSelf: 'center'
                            }}
                        >
                            <span /*className="text-color-primary"*/ style={{}}>
                                Mint a Kadcar
                            </span>
                        </div>
                        <div style={row2Styles}>
                            <div>
                                <span style={{ fontSize: '16px', fontStyle: 'italic', color: 'white' }}>Whitelists: </span>
                                <span style={{ fontSize: '16px', color: 'whitesmoke' }}> {accountRecordInfo['whitelists-remaining']['int']} </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '16px', fontStyle: 'italic', color: 'white' }}>Free Mints: </span>
                                <span style={{ fontSize: '16px', color: 'whitesmoke' }}> {accountRecordInfo['free-mints-remaining']['int']} </span>
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: '5px' }}>
                                <span style={{ fontSize: '1.5vh', fontStyle: 'italic'}}>Minting on Chain</span>&nbsp;
                                <span style={{ fontSize: '1.5vh', fontStyle: 'italic', color: "#f854e4", fontWeight: 'bold' }}>13: {currentPricePerKadcar} </span>
                            </div>
                            <div style={{ fontSize: "16px", marginBottom: "4px" }}>
                                Model:
                            </div>
                            <div style={subColInputStyles}>
                                <Select
                                    options={KADCAR_NFT_OPTIONS}
                                    onChange={onSelectModelOption}
                                    value={KADCAR_NFT_OPTIONS[0]}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "4px" }}>
                            <div style={{ fontSize: "16px", marginBottom: "4px" }}>
                                Amount:
                            </div>
                            <div style={subColInputStyles}>
                                <input
                                    style={{
                                        height: "45px",
                                        borderRadius: "0.375rem",
                                        padding: "0px 10px",
                                    }}
                                    type={"number"}
                                    min={"0"}
                                    value={amountToMint}
                                    onChange={(event) =>
                                        event.target.value && updateTotalMintPrice(event.target.value)
                                    }
                                    placeholder={"e.g. 2"}
                                    className="input_edit"
                                />
                            </div>
                        </div>
                        <div style={{}}>
                            <div
                                style={{
                                    marginRight: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{ marginTop: "8px", fontSize: "16px" }}>
                                    {" "}
                                    Total for {amountToMint} Kadcars:{" "}
                                    <span style={{ color: "#f854e4" }}>
                                        {currentMintPrice} KDA
                                    </span>{" "}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                        <span style={{ fontSize: '1.5vh', fontStyle: 'italic' }}>Base Price per Kadcar: {currentPricePerKadcar} </span>
                                    </div>
                                </div>
                                {/* <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                                    <span style={{ fontSize: "1.2vh", fontStyle: "italic"}}>
                                        *Price does not reflect actual mint price
                                    </span>
                                </div> */}
                            </div>
                            <Button
                                onClick={initiateMintKadcar}
                                color={"#fff"}
                                disabled={checkIfReadyToMint()}
                                className="buttons"
                                style={{ width: "100%", backgroundColor: "#f854e4" }}
                            >
                                Mint!
                            </Button>
                        </div>
                    </div> :
                    <div style={modalStyles}>
                        <div style={{ fontSize: "24px", fontWeight: "bold", color: "lightgray" }}>
                            <span /*className="text-color-primary"*/ style={{}}>
                                Price changed! Please Try Again
                            </span>
                        </div>
                        <div style={row2Styles}>
                            <Button onClick={() => retryMint()}>
                                Retry Mint
                            </Button>&nbsp;
                            <Button onClick={() => clearMintTransaction()}>
                                Cancel Transaction
                            </Button>
                        </div>
                        <div style={rowStyles}>
                            New Price: {tryLoadLocal('currentMintPrice')}
                        </div>
                    </div>
            }
        </Modal>
    );
};

const modalStyles = {
    width: "100%",
    // height: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
};

// const headerStyles = {
//     width: "100%",
//     height: "20%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     // backgroundColor:'blue'
// };

const rowStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "95%",
};

const row2Styles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: '16%',
    width: '100%',
    marginTop: '5%',
    marginBottom: '5%'
}

const subColLabelStyles = {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    marginRight: "5px",
    alignContent: "center",
};

const subColInputStyles = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export { MintModal };
