import React, { useContext, useEffect, useState } from "react";
import { PactContext } from "../../pact/PactContextProvider";
import {
    SCREEN_NAMES,
    SHEETS_WALLET_ADDRESS_LABEL,
    USER_KADCAR_GALLERY_LABEL,
} from "../../utils/Constants";
import { KadcarGameContext } from "./KadcarGameContextProvider";
import { KadcarCard } from "./kadcarCard/KadcarCard";
import { KadcarCardGrid } from "./KadcarCardGrid";
// import VIDEO from "../../assets/videos/neon.MP4";
import VIDEO from "../../assets/videos/Mint.mp4";
// import VIDEO from "../../assets/videos/demo.mov";
import ReactPlayer from "react-player/lazy";
import { BsPlayCircle } from "react-icons/bs";
import THUMBNAIL from "./../../assets/images/darkLogo.jpg";
import Button from "../elements/Button";
import { getSheetRows } from "../../utils/SheetsUtils";
import {
    WHITELIST_WINNERS_SHEET_ID,
    WHITELIST_WINNERS_SPREADSHEET_ID,
} from "../../secrets/constants";
import { KadcarPreview } from "./KadcarPreview";
import KadcarAccountStatement from "./KadcarAccountStatement";

//This renders the default screen to render in the screen container before any actions are taken by the user
const DefaultScreen = () => {
    const [userWLCheckerInput, setUserWLCheckerInput] = useState("");

    async function getWhitelistSpots() {
        const rows = await getSheetRows(
            WHITELIST_WINNERS_SPREADSHEET_ID,
            WHITELIST_WINNERS_SHEET_ID
        );

        rows.filter(
            (row) => userWLCheckerInput === row[SHEETS_WALLET_ADDRESS_LABEL]
        );
    }
    const { account, accountBalance, isXwallet } = useContext(PactContext);

    return (
        <div style={defaultScreenStyles}>
            {/* <div>
                {account ? (
                    <KadcarAccountStatement
                        account={account}
                        accountBalance={accountBalance}
                    />
                ) : null}
            </div> */}
            <div
                className="hero-figure reveal-from-bottom illustration-element-01 fixWidth"
                data-reveal-value="20px"
                data-reveal-delay="100"
                style={{
                    height: "70%",
                    alignSelf: "center",
                    borderRadius: "15px",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.9)",
                }}
            >
                <ReactPlayer
                    playing
                    controls
                    url={VIDEO}
                    width={"100%"}
                    height={"100%"}
                    // light={THUMBNAIL}
                    playIcon={<BsPlayCircle size={85} color={"white"} />}
                />
            </div>
            <div
                style={{
                    width: "100%",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                }}
            >
            </div>
        </div>
    );
};

//This renders a gallery displaying all Kadcars currently owned by the user
const MyKadcarGallery = () => {
    const { account } = useContext(PactContext);
    const { myKadcars, myKadcarIds, loadingNfts } = useContext(KadcarGameContext);

    return (
        <>
            {account === null ? (
                // <div>Please Connect Your Wallet</div> :
                <KadcarCardGrid disconnected={true} />
            ) : (
                <>
                    {
                        // (!loadingNfts && (myKadcars?.length === myKadcarIds?.length)) ?
                        !loadingNfts ?
                            <KadcarCardGrid
                                kadcars={myKadcars}
                                kadcarIds={myKadcarIds}
                                gallery={USER_KADCAR_GALLERY_LABEL}
                            /> :
                            <div>Loading...</div>
                    }
                </>
            )}
        </>
    );
};

//This renders a gallery displaying all Kadcars currently in existence
const AllKadcarGallery = () => {
    const { allKadcars } = useContext(KadcarGameContext);

    return <KadcarCardGrid kadcars={allKadcars} />;
};

const MainHeaderScreenContainer = () => {
    const { currentScreen } = useContext(KadcarGameContext);

    useEffect(() => {}, [currentScreen]);

    return (
        <div style={screenContainerStyles}>
            {currentScreen === null && <DefaultScreen />}
            {currentScreen === SCREEN_NAMES.MY_KADCARS && <MyKadcarGallery />}
            {currentScreen === SCREEN_NAMES.ALL_KADCARS && <AllKadcarGallery />}
            {currentScreen === SCREEN_NAMES.KADCAR_PREVIEW && <KadcarPreview />}
        </div>
    );
};

const screenContainerStyles = {
    width: "100%",
    // height: "575px",
    flexDirection: "row",
    justifyContent: "center",
    // backgroundColor:'red'
};

const defaultScreenStyles = {
    width: "100%",
    height: "575px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // backgroundColor:'blue'
};

export {
    KadcarCard,
    MyKadcarGallery,
    AllKadcarGallery,
    MainHeaderScreenContainer,
};
