import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import PagerContainer from "./PagerContainer";
import H_4 from "../assets/iso/h_4.png";
import F_1 from "../assets/iso/f_1.png";
import F_2 from "../assets/iso/f_2.png";
import F_3 from "../assets/iso/f_3.png";
import F_4 from "../assets/iso/f_4.png";
import F_5 from "../assets/iso/f_5.png";
import F_6 from "../assets/iso/f_6.png";
import F_7 from "../assets/iso/f_7.png";
import F_8 from "../assets/iso/f_8.png";

const Team = () => {
    return (
        <Box id="team">
            <Box
                background={` linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${H_4}), lightgray -146px -483.112px / 199.479% 188.401% no-repeat`}
                // bgPos={"center"}
                bgSize={"cover"}
            >
                <PagerContainer>
                    <Box py={["2.56rem", null, "5.13rem"]}>
                        <Box>
                            <Text
                                className="textClip"
                                fontSize={["40px", null, "80px"]}
                                maxW="59rem"
                                mx="auto"
                                lineHeight={"normal"}
                                textAlign="center"
                            >
                                TEAM
                            </Text>{" "}
                            <Text
                                className="textClip"
                                fontSize={["28px", null, "42px"]}
                                maxW="59rem"
                                mx="auto"
                                lineHeight={"normal"}
                                textAlign="center"
                            >
                                Co-Founders
                            </Text>
                            <Box
                                overflowX={"auto"}
                                py="2.62rem"
                                pl={["1rem", null, "0px"]}
                            >
                                <Flex
                                    gap="1rem"
                                    justify={["left", null, "center"]}
                                >
                                    {[
                                        {
                                            id: 1,
                                            name: "Robertas. R",
                                            job: "Team lead",
                                            imgUrl: F_1,
                                        },
                                        {
                                            id: 2,
                                            name: "Mohannad. A",
                                            job: "Tech Lead",
                                            imgUrl: F_2,
                                        },
                                        {
                                            id: 3,
                                            name: "Mohammed. K",
                                            job: "Game & Art Lead",
                                            imgUrl: F_3,
                                        },
                                        {
                                            id: 4,
                                            name: "Omar. S",
                                            job: "Operation & Finance Lead",
                                            imgUrl: F_4,
                                        },
                                    ].map((item) => {
                                        return (
                                            <Box key={item?.id} flexShrink={0}>
                                                <Image
                                                    src={`${item?.imgUrl}`}
                                                    alt="Image"
                                                    boxSize={[
                                                        "208px",
                                                        null,
                                                        "342px",
                                                    ]}
                                                    objectFit={"cover"}
                                                    rounded={"0.5rem"}
                                                />

                                                <Text
                                                    mt="1.56rem"
                                                    fontSize={[
                                                        "20px",
                                                        null,
                                                        "28px",
                                                    ]}
                                                    textAlign={"center"}
                                                    className="textClip"
                                                >
                                                    {item.name}
                                                </Text>

                                                <Text
                                                    mt="8px"
                                                    fontSize={[
                                                        "16px",
                                                        null,
                                                        "16px",
                                                    ]}
                                                    textAlign={"center"}
                                                    className="textClip"
                                                >
                                                    {item?.job}
                                                </Text>
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            </Box>
                        </Box>

                        <Box mt={["0.5rem", null, "2.25rem"]}>
                            <Text
                                className="textClip"
                                fontSize={["1.75rem", null, "2.625rem"]}
                                maxW="59rem"
                                mx="auto"
                                lineHeight={"normal"}
                                textAlign="center"
                            >
                                Rest of the team
                            </Text>

                            <Box
                                overflowX={"auto"}
                                py="2.62rem"
                                pl={["1rem", null, "0px"]}
                            >
                                <Flex
                                    gap="1rem"
                                    justify={["left", null, "center"]}
                                >
                                    {[
                                        {
                                            id: 1,
                                            name: "Saif Ali Khan",
                                            job: "Sr Game Dev",
                                            imgUrl: F_5,
                                        },
                                        {
                                            id: 2,
                                            name: "Ahmed. M",
                                            job: "UI Developer",
                                            imgUrl: F_6,
                                        },
                                        {
                                            id: 3,
                                            name: "Imani. M",
                                            job: "UX Lead",
                                            imgUrl: F_7,
                                        },
                                        {
                                            id: 4,
                                            name: "Awais. M",
                                            job: "3D Desiger",
                                            imgUrl: F_8,
                                        },
                                    ].map((item) => {
                                        return (
                                            <Box key={item?.id} flexShrink={0}>
                                                <Image
                                                    src={`${item?.imgUrl}`}
                                                    alt="Image"
                                                    boxSize={[
                                                        "208px",
                                                        null,
                                                        "342px",
                                                    ]}
                                                    objectFit={"cover"}
                                                    rounded={"0.5rem"}
                                                />

                                                <Text
                                                    mt="1.56rem"
                                                    fontSize={[
                                                        "20px",
                                                        null,
                                                        "28px",
                                                    ]}
                                                    textAlign={"center"}
                                                    className="textClip"
                                                >
                                                    {item.name}
                                                </Text>

                                                <Text
                                                    mt="8px"
                                                    fontSize={[
                                                        "16px",
                                                        null,
                                                        "16px",
                                                    ]}
                                                    textAlign={"center"}
                                                    className="textClip"
                                                >
                                                    {item?.job}
                                                </Text>
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                </PagerContainer>
            </Box>
        </Box>
    );
};

export default Team;
