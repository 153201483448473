import Modal from "../components/elements/Modal";
import Select from 'react-select';
import { BACKEND_BASE_URL, DEFAULT_GAS_LIMIT, DEFAULT_REQUEST_HEADERS, NETWORK_ID, STICKER_LOCATIONS, UNIVERSAL_LEDGER } from "../utils/Constants";
import { useContext, useEffect, useState } from "react";
import ButtonGroup from "../components/elements/ButtonGroup";
import Button from "../components/elements/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { defineMetaData, getNetworkUrl, readFromContract } from "../pact/PactUtils";
import { PactContext } from "../pact/PactContextProvider";
import Pact from "pact-lang-api";
import { useApplyStickerUpgradeContract, useGetStickerByOwner } from "../pact/PactContractHooks";

const UPGRADE_OPTIONS = [
    {
        value: "sticker 1",
        label: "sticker 1"
    }
]

const UpgradeModal = ({ show, setShow, selectedKadcar }) => {
    const [upgradeId, setUpgradeId] = useState(null);
    const [userStickers, setUserStickers] = useState(null);
    const [upgradeType, setUpgradeType] = useState(null);
    const [upgradeLocation, setUpgradeLocation] = useState(null);
    
    const { account, accountBalance, chainId, netId, gasPrice, sendTransaction } = useContext(PactContext);
    
    const getUserStickers = useGetStickerByOwner();
    const attachStickerToKadcar = useApplyStickerUpgradeContract();

    useEffect(() => {
        if (show) {
            getUserStickers(setUserStickers);
        }
    }, [show]);

    function handleClose() {
        setShow(false);
    }

    function handleClear() {
        setUpgradeType(null);
    }

    function handleSelectedUpgradeType(upgradeOption) {
        setUpgradeType(upgradeOption.value);
    }

    function handleSelectUpgradeAsset(assetOption) {
        setUpgradeId(assetOption.value);
    }

    function handleSelectedUpgradeLocation(locationOption) {
        setUpgradeLocation(locationOption.value);
    }

    //TODO: make this dynamic
    // kadcar id obtained from current selected car manifest
    // sticker id obtained from modal
    // uv location obtained from manifest 
    async function initiateUpgrade() {
        try {
            attachStickerToKadcar(selectedKadcar.data[1]['datum']['vehicle-information']['vin'], upgradeId, null, null);
        } catch(e) {
            toast.error("Error with upgrade request")
        }
    }

    //TODO: kadcar id, sticker id and uv location should be made into states
    async function callbackToR2R(pact_id) {
        let upgrade_id = ""
        let config = {
            headers: DEFAULT_REQUEST_HEADERS
        }
        
        let payload = {
            upgrade_type: "sticker",
            pact_id: pact_id,
            upgrade_id: upgrade_id,
            kadcar_id: "Kadcars#K:2 Diamond Back->>>>>>" 
        }

        let res = await axios.post(BACKEND_BASE_URL + 'r2r/apply_upgrade/', payload, config)
        console.log(res)
        
        let envData = {
            "kc": accountBalance.guard,
            "transformation-list": res.data
        }


    }

    return (
        <Modal show={show} handleClose={handleClose}>
            <div style={modalStyles}>
                <div
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "lightgray",
                        alignSelf: 'center',
                        marginBottom: '10px'
                    }}
                >
                    <span>Upgrade Center</span>
                </div>
                <div>
                    <div style={{ textAlign: 'left', fontSize: "16px", marginBottom: "4px" }}>
                        Upgrade:
                    </div>
                    <div style={subColInputStyles}>
                        <Select options={UPGRADE_OPTIONS} onChange={handleSelectedUpgradeType}/>
                    </div>
                    {
                        upgradeType ?
                            <div>
                                <div style={{ textAlign: 'left', fontSize: "16px", marginBottom: "4px" }}>
                                    Location:
                                </div>
                                <div style={subColInputStyles}>
                                    <Select options={STICKER_LOCATIONS} onChange={handleSelectedUpgradeLocation} menuPlacement='top'/>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div style={{'marginTop': '60px'}}>
                    <ButtonGroup>
                        <Button styles={{"fontColor" : "black"}} className={"buttons"} color={'light'} onClick={handleClear}>
                            Clear
                        </Button>
                        <Button className={"buttons"} color={'primary'} disabled={upgradeLocation === null} onClick={initiateUpgrade}>
                            Upgrade!
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </Modal>
    )
}

const modalStyles = {
    width: "100%",
    // height: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
};

// const headerStyles = {
//     width: "100%",
//     height: "20%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "flex-start",
//     // backgroundColor:'blue'
// };

const rowStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "95%",
};

const row2Styles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: '16%',
    width: '100%',
    marginTop: '5%',
    marginBottom: '5%'
}

const subColLabelStyles = {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    marginRight: "5px",
    alignContent: "center",
};

const subColInputStyles = {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%"
};

export { UpgradeModal }