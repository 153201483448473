import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const CountdownTimer = () => {
    return (
        <Box className="">
            <Timer />
        </Box>
    );
};

const Timer = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [champMintActive, setChampMintActive] = useState(false);
    const [publicMintActive, setPublicMintActive] = useState(false);
    const [message, setMessage] = useState();

    const championStart = Date.UTC(2023, 1, 11, 0, 0, 0);
    const publicStart = Date.UTC(2023, 1, 11, 6, 0, 0);

    const getTime = (activeWindow) => {
        // let time = Date.parse(deadline) - Date.now();
        let time = null;

        if (activeWindow == "Public") {
            time = new Date(publicStart - publicStart);
        } else if (activeWindow == "Champ") {
            time = new Date(publicStart - Date.now());
        } else {
            time = new Date(championStart - Date.now());
        }

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));

        return time;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (Date.now() < championStart) {
                setMessage("Mint is Not Live Yet");
                getTime("None");
            }
            else if (Date.now() < publicStart) {
                !champMintActive && setChampMintActive(true);
                setMessage("Champion Mint is Now Live!");
                getTime("Champ");
            } else {
                !publicMintActive && setPublicMintActive(true);
                champMintActive && setChampMintActive(false);
                setMessage("Public Mint is Now Live!");
                getTime("Public")
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function getActiveMintWindow() {
        let mintWindow = "";
        
        if (!champMintActive && !publicMintActive) {
            mintWindow = "Champion";
        } else if (champMintActive && !publicMintActive) {
            mintWindow = "Public";
        }
        
        return mintWindow;
    }

    return (
        <>
            <div style={{ marginBottom: '5px' }}>{message}</div>
            {
                ((!champMintActive && !publicMintActive) ||
                (champMintActive && !publicMintActive)) &&
                <div style={{ marginBottom: '8px' }}>
                    <span style={{ fontSize: '1.5vh', fontStyle: 'italic'}}>
                        Time left until <span style={{ color: "#f854e4"}}>{ getActiveMintWindow() }</span> mint window:
                    </span>
                </div>
            }
            <Box className="timer" role="timer">
                <Box className="col-4">
                    <Box className="box">
                        <Box id="day">{days < 10 ? "0" + days : days}</Box>

                        <span className="text">Days</span>
                    </Box>
                </Box>
                <Box className="col-4">
                    <Box className="box">
                        <Box id="hour">{hours < 10 ? "0" + hours : hours}</Box>
                        <span className="text">Hours</span>
                    </Box>
                </Box>
                <Box className="col-4">
                    <Box className="box">
                        <Box id="minute">
                            {minutes < 10 ? "0" + minutes : minutes}
                        </Box>
                        <span className="text">Minutes</span>
                    </Box>
                </Box>
                <Box className="col-4">
                    <Box className="box">
                        <Box id="second">
                            {seconds < 10 ? "0" + seconds : seconds}
                        </Box>
                        <span className="text">Seconds</span>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CountdownTimer;
