import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import H_7 from "../assets/iso/h_7.png";
import H_5 from "../assets/iso/h_5.png";
import H_6 from "../assets/iso/h_6.png";

const Upgrade = () => {
    return (
        <Box id="game">
            <Box pos="relative">
                <Text
                    className="textClip"
                    fontSize={["64px"]}
                    lineHeight={"normal"}
                    pos="absolute"
                    top="134px"
                    left="50%"
                    w="100%"
                    textAlign="center"
                    transform={"translateX(-50%)"}
                    display={["none", null, "block"]}
                >
                    YOUR KADCAR
                </Text>
                <SimpleGrid columns={[1, null, 3]}>
                    <Box
                        background={`linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${H_7}), lightgray 0px 0px / 100% 108.889% no-repeat`}
                    >
                        <Flex
                            align="center"
                            w="100%"
                            minH={["fit-content", "100vh"]}
                            justify={"flex-start"}
                            pt={["0rem", null, null, "311px"]}
                            pb={["0rem", null, null, "150px"]}
                        >
                            <Box
                                px={["36px"]}
                                py="4.8rem"
                                fontSize={["14px", null, "20px"]}
                            >
                                <Text
                                    w="100%"
                                    className="textClip"
                                    fontSize={["28px", null, "40px"]}
                                    lineHeight={"normal"}
                                >
                                    UPGRADE
                                </Text>

                                <Text mt="1rem" color="white">
                                    Too slow on the track? Corners are too sharp
                                    and handling is too lose?
                                    <Text mt="1rem" color="white">
                                        No worries. KadCars got you covered.
                                        Head over to the garage and upgrade your
                                        car with other car parts NFT’s.
                                    </Text>
                                    <Text mt="1rem" color="white">
                                        {" "}
                                        (Upcoming feature)
                                    </Text>
                                </Text>
                            </Box>
                        </Flex>
                    </Box>

                    {/* Part 2 */}

                    <Box
                        background={`linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${H_5}), lightgray 0px 0px / 100% 108.889% no-repeat`}
                    >
                        <Flex
                            align="center"
                            w="100%"
                            minH={["fit-content", "100vh"]}
                            justify={"flex-start"}
                            pos="relative"
                            pt={["0rem", null, null, "311px"]}
                            pb={["0rem", null, null, "150px"]}
                        >
                            <Box
                                px={["36px"]}
                                py="4.8rem"
                                fontSize={["14px", null, "20px"]}
                            >
                                <Text
                                    w="100%"
                                    className="textClip"
                                    fontSize={["28px", null, "40px"]}
                                    lineHeight={"normal"}
                                >
                                    CUSTOMIZE
                                </Text>
                                <Text mt="1rem" color="white">
                                    Do not like the color of the car you minted?
                                    It’s alright - we get that.
                                </Text>{" "}
                                <Text mt="1rem" color="white">
                                    Go to the garage and apply those fresh
                                    stickers onto your car and level up your
                                    looks.
                                </Text>
                            </Box>
                        </Flex>
                    </Box>

                    {/* {3} */}
                    <Box
                        background={`linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${H_6}), lightgray 0px 0px / 100% 108.889% no-repeat`}
                    >
                        <Flex
                            align="center"
                            w="100%"
                            minH={["fit-content", "100vh"]}
                            justify={"flex-start"}
                            pt={["0rem", null, null, "311px"]}
                            pb={["0rem", null, null, "150px"]}
                        >
                            <Box
                                px={["36px"]}
                                py="4.8rem"
                                fontSize={["14px", null, "20px"]}
                            >
                                <Text
                                    w="100%"
                                    className="textClip"
                                    fontSize={["28px", null, "40px"]}
                                    lineHeight={"normal"}
                                >
                                    RACE
                                </Text>
                                <Text mt="1rem" color="white">
                                    Each KadCar NFT is a unique 3D model that
                                    loads into the game, which then can be used
                                    to race on track.
                                </Text>{" "}
                                <Text mt="1rem" color="white">
                                    Not many NFT’s can do that.
                                </Text>
                            </Box>
                        </Flex>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default Upgrade;
